@use 'app/project/components/dropdown/Dropdown.scss';

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
@import '../src/assets/fonts/futura-font.css';

@import '@fortawesome/fontawesome-free/css/all.css';
@import 'flatpickr/dist/flatpickr.css';
@import 'quill/dist/quill.snow.css';
@import 'intl-tel-input/build/css/intlTelInput.css';

// Variables

@import 'styles/variables';

body {
  .intercom-lightweight-app {
    .intercom-launcher {
      transform: scale(0.9);
    }
  }

  .intercom-namespace {
    .intercom-app {
      .intercom-launcher-frame {
        transform: scale(0.9);
      }

      .intercom-launcher {
        transform: scale(0.9);
      }
    }
  }

  .fixed-width {
    width: 100vw;
    position: fixed;
  }
}

//      Fixes

@import 'styles/fixes/angular-fix';
@import 'styles/fixes/bootstrap-fix';

//      Base

@import 'styles/base/forms';
@import 'styles/base/links';
@import 'styles/base/lists';
@import 'styles/base/icons';
@import 'styles/base/typography-base';
@import 'styles/base/utilities';

//      Generic

@import 'styles/generic/borders';
@import 'styles/generic/helpers';
@import 'styles/generic/spacing';

//      Components

@import 'styles/components/checkboxes';
@import 'styles/components/icons';
@import 'styles/components/images';
@import 'styles/components/spinner';
@import 'styles/components/select';
@import 'styles/components/inputs';
@import 'styles/components/tags';
@import 'styles/components/borders';
@import 'styles/components/buttons';

//      Partials

@import 'styles/partials/filter-panel/globals';
@import 'styles/partials/filter-panel/points-main';
@import 'styles/partials/filter-panel/defects-view';
@import 'styles/partials/filter-panel/new-and-edit';
@import 'styles/partials/filter-panel/settings';

//      Views

@import 'styles/views/globals';

//
// Global styles, temp workaround
//

@import 'app/project/modules/layout/side-panel/side-panel-component/side-panel.component.scss';
@import 'app/project/modules/site/site-table/site-table.component.scss';
@import 'app/project/modules/site/site-table/custom-table/table.scss';
@import 'app/project/modules/site/site-timeline/timeline.scss';
@import 'app/project/modules/site/site-options/actionDropdown/ActionDropdown.scss';
@import 'app/project/modules/site/site-options/actionDropdown/ActionFieldsDropdown.scss';
@import 'app/project/modules/site/site-options/group/GroupDropdown.scss';
@import 'app/project/modules/site/site-options/site-options-group-button/group-button-dropdown/group-or-sort-button-order/group-or-sort-button-order-dropdown/group-or-sort-button-order-dropdown.scss';
