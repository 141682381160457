$z-index-user-management-header: 100;
$z-index-user-management-footer: 110;
$z-index-site-plan: 200;
$z-index-vertical-scrollbar: 399;
$z-index-half-modal: 400;
$z-index-top-bar: 400;
$z-index-ql-mentions: 401;
$z-index-site__header: 401;
$z-index-site-overview-table-overlayer: 500;
$z-index-notificationsPanel: 501;
$z-index-filter-panel: 501;
$z-index-side-panel: 501;
$z-index-point-container: 505;
$z-index-new-asset-back-wrapper: 500;
$z-index-gallery: 510;
$z-index-action-bar: 601;
$z-index-browsersBar: 601;
$z-index-auth-page: 800;
$z-index-document-preview: 799;
$z-index-browsers-page: 800;
$z-index-browsers-page: 800;
$z-index-modal-backdrop: 900;
$z-index-filter-dropdown: 925;
$z-index-dropdown: 925;
$z-index-prompt: 950;
$z-index-notificationsPanel--mobile: 1000;
$z-index-reminderAlert__background: 1000;
$z-index-reminderAlert: 1001;
