@import '../../variables';

.settings-page .filterPanel {
  .accounts {
    position: fixed;
    width: $side-panel;
    height: calc(100% - #{$top-nav} - 5.17rem - 5.17rem);
    overflow-y: auto;
    scrollbar-color: $grey-400 $transparent;
    scrollbar-width: thin;
  }

  .account {
    ul {
      padding-bottom: $spacer-s;
    }
  }

  .account-name,
  .site-name {
    display: block;
    padding-top: 0.45em;
    padding-bottom: 0.45em;
    line-height: 1.3;
  }

  .account-name {
    margin: 0.7em 0;
    padding-left: $spacer-xl;
    color: $panel-font;
  }

  .site-name {
    background: red;
    padding-left: $spacer-xl;
    color: $white;

    .sw-icon {
      margin-top: -$spacer-xxs;
      margin-right: $spacer-xs;
    }
  }
}

.new-site-settings .filterPanel {
  padding-top: $spacer-xl;

  .btn {
    margin-bottom: $spacer-m;
  }
}
