@import '../variables';

.border-right,
.rg-left {
  border: none;
  border-right: solid $spacer-xs;
}

.border-right.border-grey,
.rg-left {
  border-right-color: $grey-200;
}
