/* table THEME: */
.os-theme-table {
  box-sizing: border-box;
  --os-size: 1rem;
  --os-padding-perpendicular: 0;
  --os-padding-axis: 0;
  --os-track-border-radius: 2.5rem;
  --os-handle-interactive-area-offset: 0.4rem;
  --os-handle-border-radius: 2.5rem;
  --os-handle-bg: #98a2b3;
  --os-handle-bg-hover: #bec4cc;
  --os-handle-bg-active: #98a2b3;
  --os-track-bg: #f9fafb;
  --os-track-bg-hover: #f9fafb;
  --os-track-bg-active: #f9fafb;
}

.os-no-css-vars.os-theme-table.os-scrollbar .os-scrollbar-handle {
  border-radius: 2.4rem;
}
.os-no-css-vars.os-theme-table.os-scrollbar .os-scrollbar-track {
  border-radius: 2.4rem;
}

.os-theme-table.os-scrollbar-horizontal {
  border-bottom: 0.1rem solid $white;
  box-sizing: content-box;
}

.os-theme-table.os-scrollbar-vertical {
  border-right: 0.1rem solid $white;
  box-sizing: content-box;
  z-index: $z-index-vertical-scrollbar;

  .os-scrollbar-track {
    background: none;
  }

  .os-scrollbar-track::after {
    content: ('');
    position: absolute;
    width: 8rem;
    height: 3.5rem; // custom value to match scrollbar bg with table footer
    bottom: -$spacer-s;
    border-top: $thin-grey-300-border; // $table-border
    background: $grey-50;
    z-index: -1;
  }
}

.os-no-css-vars.os-theme-table.os-scrollbar-horizontal {
  padding: $spacer-xxs;
  right: 1rem;
  height: 1rem;
}
.os-no-css-vars.os-theme-table.os-scrollbar-horizontal.os-scrollbar-rtl {
  left: 1rem;
  right: 0;
}
.os-no-css-vars.os-theme-table.os-scrollbar-horizontal .os-scrollbar-handle {
  min-width: $spacer-xxl;
  max-width: none;
}
.os-no-css-vars.os-theme-table.os-scrollbar-horizontal .os-scrollbar-handle:before {
  top: calc(($spacer-xxs + 0.4rem) * -1);
  bottom: calc($spacer-xxs * -1);
}
.os-no-css-vars.os-theme-table.os-scrollbar-vertical {
  padding: $spacer-xxs;
  bottom: 1rem;
  width: 1rem;
}

.os-no-css-vars.os-theme-table.os-scrollbar-vertical .os-scrollbar-handle {
  min-height: $spacer-xxl;
  max-height: none;
}
.os-no-css-vars.os-theme-table.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: calc(($spacer-xxs + 0.4rem) * -1);
  right: calc($spacer-xxs * -1);
}
.os-no-css-vars.os-theme-table.os-scrollbar-vertical.os-scrollbar-rtl .os-scrollbar-handle:before {
  right: calc(($spacer-xxs + 0.4rem) * -1);
  left: calc($spacer-xxs * -1);
}
.os-no-css-vars.os-theme-table .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.44);
}
.os-no-css-vars.os-theme-table:hover .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.55);
}
.os-no-css-vars.os-theme-table:active .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.66);
}
