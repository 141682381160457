@use '../../../../../../styles/base/typography';
@use '../../../../../../styles/variables' as *;
@use '../../../../../../styles/generic/mixins' as m;

$firstPosition: -1.7em;
$secondPosition: -3.3em;
$secondPositionAdjusted: -3.2em !important;
$thirdPosition: -4.9em;
$thirdPositionAdjusted: -4.8em !important;

.table__row--grouping {
  display: inline-flex;
  height: 3.7em;
  width: 1.7em;
  position: absolute;
  top: 0;
}

.customSiteTable__checkbox__wrapper {
  width: 3.7em;
  height: 3.7em;
  box-sizing: border-box;
  @include m.flex-center;
  border-left: $table-border;
  border-top: $table-border;
  border-bottom: $table-border;
}

.customSiteTable__checkbox__wrapper__background {
  position: absolute;
  width: 3.7em;
  height: 3.7em;
  z-index: -1;
}

.customSiteTable__checkbox__wrapper--noBorder {
  border-left: none;
}

.customSiteTable__checkbox__wrapper--last {
  border-bottom-left-radius: 1em;
  border-left: $table-border;
}

.table__row--grouping--first-margin {
  left: -1.7em;
  border-left: $table-border;
  width: 1.7em !important;
}

.table__row__grouping--second-margin {
  left: -3.2em;
  border-left: $table-border;
}

.table__row__grouping--third-margin {
  left: -4.8em;
  border-left: $table-border;
}

.table__row__grouping--last {
  border-radius: 0 0 0 1em;
}

.table__row__grouping--second--lastGroupRow {
  border-radius: 0 0 0 1em;
}

.table__row--header-1-margin {
  // margin-left: 1.6em !important;
}

.table__row--header-2-margin {
  // margin-left: 32px !important;
}

.table__row--header-3-margin {
  // margin-left: 48px !important;
}

.table__groupLevelIndicator {
  position: absolute;
  height: 3.7em;
}

.table__header--group-2-margin {
  left: 0;
}

.table__header--group-1-margin {
  position: absolute;
  left: 1.5em;
}

.table__header--group-3-margin {
  left: -4.4em;
}

.table__groupLevelIndicator--1--1 {
  background-color: $white;
  left: -1px;
  border-left: 0.1em solid #d0d5dd;
  width: 1.7em !important;
}

.table__groupLevelIndicator--0--3 {
  background-color: $white;
  border-left: none;
  left: 0;
  width: 2.5em !important;
}

.table__groupLevelIndicator--1 {
  z-index: -2;
  background-color: $white;
  width: 1.6em;
  position: absolute;
  left: -0.1em;
}

.table__groupLevelIndicator--2 {
  z-index: -2;
  background-color: $table-group-3-color;
  width: 1.6em;
  border-left: $table-border;
}

.table__groupLevelIndicator--3 {
  z-index: -1;
  background-color: $table-group-2-color;
  width: 1.6em;
  border-left: $table-border;
}

.emptyRowGroup--1 {
  width: 1.6em;
  background-color: $white;
  position: sticky;
  left: 0;
}

.emptyRowGroup--1--end {
  width: 1.6em;
  background-color: $white;
  right: -3.2em;
  display: none;
}

.emptyRowGroup--1--last {
  @extend .emptyRowGroup--1;

  width: inherit;
  border-radius: 0 0 0 1em;
}

.emptyRowGroup--1--last--end {
  @extend .emptyRowGroup--1--end;

  width: inherit;
  border-radius: 0 0 1em 0;
}

.emptyRowGroup--2 {
  background-color: $table-group-3-color;
  left: 1.6em;
  border-left: $table-border;
  position: sticky;
  width: 1.6em;
}

.emptyRowGroup--2--end {
  background-color: $table-group-3-color;
  border-right: $table-border;
  right: -1.6em;
}

.emptyRowGroup--2--end--thirdGroup {
  background-color: $table-group-3-color;
  border-right: $table-border;
  width: 3.4em;
  right: -3.3em;
}

.emptyRowGroup--2--last {
  @extend .emptyRowGroup--2;

  border-left: $table-border;
  border-radius: 0 0 0 1em;
  border-bottom: $table-border;
  z-index: 1;
  left: 1.8em;

  width: inherit;
}

.emptyRowGroup--2--last--end {
  @extend .emptyRowGroup--2--end;

  z-index: 1;
  border-right: $table-border;
  border-radius: 0 0 1em 0;
  border-bottom: $table-border;
  width: inherit;
}

.emptyRowGroup--3 {
  background-color: $table-group-2-color;
  left: 3.3em;
  border-left: $table-border;
  position: sticky;
}

.emptyRowGroup--3--end {
  background-color: $table-group-2-color;
  right: -1.7em;
  border-right: $table-border;
  width: 1.7em;
}

.emptyRowGroup--3--end--thirdGroup {
  background-color: $table-group-2-color;
  right: -1.7em;
  border-right: $table-border;
  width: 2em;
}

.emptyRowGroup--3--noBorder {
  border-left: none;
}

.table__row--empty--last {
  border-bottom: $table-border;
}

.emptyRowGroup--noBorder {
  border: none;
}

.emptyRowGroup--3--last {
  @extend .emptyRowGroup--3;

  width: inherit;
  border-left: $table-border;
  border-bottom: $table-border;
  border-radius: 0 0 0 1em;
}

.emptyRowGroup--3--last--end {
  @extend .emptyRowGroup--3--end;

  width: inherit;
  border-right: $table-border;
  border-bottom: $table-border;
  border-radius: 0 0 1em 0;
}

.table__headerOptions {
  width: 2.4em;
  height: 2.4em;
  border-radius: $border-radius-full;
  @include m.flex-center;
  cursor: pointer;
  visibility: hidden;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
}

.table__headerOptions__button {
  display: contents;
}

.table__cell__groupedHeader {
  @extend .siteTableFont;

  @include m.flex-center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  color: $main-black;
  border-top: $table-border;
}

.table__cell__groupedHeader--showTotal {
  border-right: $table-border;
  border-left: $table-border;
  box-sizing: border-box;
}

.table__row--grouping--first-color {
  background-color: $white;
  border-left: none;
}

.table__row__grouping--second-color {
  background-color: $table-group-3-color !important;
}

.table__row__grouping--third-color {
  background-color: $table-group-2-color !important;
}

.table__cell__extra {
  width: 1.6em;
  height: 3.7em;
  position: absolute;
  right: -1.7em;
  background-color: $white;
}

.table__cell__extra--firstColor_3_2_background {
  right: calc($secondPosition - 1px);
  background-color: $white;
}

.table__cell__extra--firstColor_secondPosition {
  right: $secondPosition;
  background-color: $white;
}

.table__cell__extra--firstColor_firstPosition {
  right: $firstPosition;
  background-color: $white;
}

.table__cell__extra--secondColor_firstPosition {
  right: $firstPosition;
  background-color: $table-group-2-color;
  box-sizing: border-box;
}

.table__cell__extra--secondColor_3_1 {
  right: $firstPosition;
  background-color: $table-group-2-color;
  box-sizing: border-box;
  width: 1.7em;
}

.table__cell__extra--firstColor_thirdPosition {
  right: $thirdPosition;
  color: $white;
}

.table__cell__extra--thirdColor_secondPosition {
  right: $secondPosition;
  background-color: $table-group-3-color;
}

.table__cell__extra--thirdColor_firstPosition {
  background-color: $table-group-3-color;
  box-sizing: border-box;
  right: -1.6em;
  border-right: $table-border;
  width: 1.5em;
}

.table__cell__extra--3_1_background {
  background-color: $table-group-3-color;
  box-sizing: border-box;
  right: -1.7em;
  width: 1.8em;
}

.table__cell__extra--3_1 {
  background-color: $table-group-3-color;
  box-sizing: border-box;
  right: -1.7em;
  width: 1.7em;
}

.table__cell__extra--thirdColor_zeroPosition {
  right: -0.1em;
  background-color: $table-group-3-color;
}

.table__cell__extra--border {
  border-right: $table-border;
  border-top-right-radius: 1em;
  right: 0.1em;
  border-top: $table-border;
}

.table__cell__extra--border-top {
  border-top: $table-border;
}

.table__cell__extra--border-right {
  border-right: $table-border;
  box-sizing: border-box;
}

.table__cell__extra--firstColor_zeroPosition {
  right: -0.1em;
  background-color: $white;
}

.table__cell__extra--secondColor_zeroPosition {
  right: -0.1em;
  background-color: $table-group-2-color;
}

.table__cell__extra--fourthColor_2_0 {
  background-color: $table-group-1-color;
  right: 0.1em;
  border-bottom: $table-border;
  height: 3.6em;
  border-right: $table-border;
  width: 1.5em;
  box-sizing: border-box;
}

.table__cell__extra--fourthColor_3_0 {
  background-color: $table-group-1-color;
  right: 1px;
  border-bottom: $table-border;
  height: 3.6em;
  border-right: $table-border;
  width: 1.5em;
  box-sizing: border-box;
}

.table__cell__extra--adjustedSecondPosition {
  right: $secondPositionAdjusted;
}

.table__cell__extra--adjustedThirdPosition {
  right: $thirdPositionAdjusted;
}

.table__cell__extra__point {
  width: 1.6em;
  height: 3.7em;
  position: absolute;
  right: -1.7em;
  background-color: $white;
}

.table__cell__extra__point_2_1 {
  width: 1.6em;
  height: 3.7em;
  position: absolute;
  right: -1.7em;
  background-color: $table-group-3-color;
  border-right: $table-border;
  box-sizing: border-box;
}

.table__cell__extra__point_2_2 {
  width: 1.6em;
  height: 3.7em;
  position: absolute;
  right: -3.4em;
  background-color: $white;
}

.table__cell__extra__point_3_1 {
  width: 1.7em;
  height: 3.7em;
  position: absolute;
  right: -1.8em;
  background-color: $table-group-2-color;
  border-right: $table-border;
  box-sizing: border-box;
}

.table__cell__extra__point_3_2 {
  width: 1.6em;
  height: 3.7em;
  position: absolute;
  right: -3.4em;
  background-color: $table-group-3-color;
  border-right: $table-border;
  box-sizing: border-box;
}

.table__cell__extra__point_3_3 {
  width: 1.6em;
  height: 3.7em;
  position: absolute;
  right: -5em;
  background-color: $white;
}

.table__headerCell--groupingShadow {
  box-shadow: 0.5em 0 0.5em rgba(0, 0, 0, 0.1);
}

.table__cell__extra--collapsed {
  border-bottom-right-radius: 1em;
  border-bottom: $table-border;
  height: 3.6em;
}

.table__cell__extra--thirdColor_firstPosition--collapsed {
  height: 3.6em;
  border-bottom: $table-border;
}

.table__cell__extra--thirdColor_secondPosition--collapsed {
  height: 3.6em;
  box-sizing: border-box;
}

.table_header_merged__background {
  width: 1em;
  height: 3.7em;
  position: absolute;
  background: $white;
  transform: translateX(-0.1em);
}
