@import '../variables';

.check-box {
  display: inline-block;
  position: relative;
  width: $spacer-l;
  height: $spacer-l;
  border: $spacer-xxs solid $panel-font;
  border-radius: $border-radius-s;
  vertical-align: middle;

  .filter-check & {
    margin-right: 0.8em;
  }

  .filter-status .filter-check & {
    margin-right: $spacer-xs;
  }

  &.check-box-HIGH {
    border-color: $main-red !important;
  }

  &.check-box-MEDIUM {
    border-color: $main-yellow !important;
  }

  &.check-box-LOW {
    border-color: $main-blue !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: $spacer-xxs;
    left: 0.1rem;
    width: $spacer-s;
    height: $spacer-xs;
    border: $spacer-xxs solid $main-black;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
}

.check-text {
  vertical-align: middle;

  .filter-check & {
    color: $panel-font;
  }
}

.filter-check {
  label:hover .check-box::before {
    opacity: 1;
    border-color: $white;
  }

  label:hover .check-box-HIGH::before {
    border-color: $main-red !important;
  }

  label:hover .check-box-MEDIUM::before {
    border-color: $main-yellow !important;
  }

  label:hover .check-box-LOW::before {
    border-color: $main-blue !important;
  }

  input[type='checkbox']:checked {
    + label:hover .check-box::before {
      border-color: $main-black !important;
    }

    + label .check-box {
      border-color: $white;
      background: $white;
    }

    + label .check-box-HIGH {
      border-color: $main-red !important;
      background: $main-red !important;
    }

    + label .check-box-MEDIUM {
      border-color: $main-yellow !important;
      background: $main-yellow !important;
    }

    + label .check-box-LOW {
      border-color: $main-blue !important;
      background: $main-blue !important;
    }

    + label .check-text {
      color: $white;
    }
  }

  input[type='checkbox']:checked + label .check-box::before {
    opacity: 1;
  }
}
