$transparent: rgba(255, 255, 255, 0); // Safari transparency is not working as intended

$panel-font: #738389;

// new design

$gray-1: #333333;

$main-blue-opacity-20: rgba(0, 132, 248, 0.2);
$main-yellow: #ffd02e;
$main-red-disabled: rgba(228, 80, 80, 0.3);

$dashboard-main-teal: #009688;
$dashboard-main-purple: #7d619f;
$dashboard-main-green: #66b92f;

$dashboard-status-open: #3170a7;
$dashboard-status-in-progress: #6bc8f9;
$dashboard-status-to-review: #f3db12;
$dashboard-status-on-hold: #ff9801;
$dashboard-status-completed: #65b92e;
$dashboard-status-canceled: #838b99;

$dashboard-priority-high: #e54f50;
$dashboard-priority-medium: #ffce29;
$dashboard-priority-low: #4da0e5;
$white: #fff;
$container-backround: #f5f8fa;
$container-backround-2: #e9eff0;
$dashlet-border: #b1b7b9;
$main-green: #4ad69c;
$green: #7bc154;
$dashboard-footer: #f2f3f4;
$extra-icon: #829096;
$black: #000;
$priority-low: #569fd7;
$checkbox-border: #7b7b7b;

// white label
$panelFont: #2e3b4a;
$panelBg: #181d23;
$panelAccent: #2d3a40;
$panelText: #ffffff;
$panelTextHover: #41545d;

// opacity #rgba
$white-85: #ffffffd9;
$white-80: #ffffffcc;
$white-75: #ffffffc0;
$white-60: #ffffff99;
$white-30: #ffffff4c;
$white-15: #ffffff26;

$black-50: #00000080;
$black-40: #00000066;
$black-25: #00000040;
$black-15: #00000026;
$black-10: #00000019;
$black-09: #00000017;
$black-07: #00000013;
$black-0125: #00000020;
$black-01-2: #00000002;
$black-0: #ffffff00; // it's transparent but makes sense for animations and gradients
$black-80a: #000000cc;
$black-02: rgba(0, 0, 0, 0.1);

$grey-90: #e4e9ece6;
$grey-90: #979da0e6;
$grey-80: #333333cc;
$grey-80-2: #b1b7b9cc;
$grey-80-3: #f2f3f4cc;
$grey-80-4: #072742cc;
$grey-80-5: #999999cc;
$grey-80-6: #ebeff1cc;
$grey-75-2: #2d3a40c0;
$grey-50-transparent: #dde2e380;
$grey-50-2: #2d3a4080;
$grey-40: #88888866;
$grey-36: #979da05c;
$grey-35: #f2f3f459;
$grey-30-4: #42526e4c;
$grey-30-6: #252b2d4c;
$grey-28: #134e8047;
$grey-20: #88888833;
$grey-20-2: #1c467833;
$grey-15: #0084f826;

$main-red-60: #e9666699;

$scrollbar-color: #535e63;

// New colors

$main-black: #24292d;
$secondary-black: #2d3a40;
$tertiary-black: #41545d;

$grey-50: #f9fafb;
$grey-100: #f2f4f7;
$grey-200: #eaecf0;
$grey-300: #d0d5dd;
$grey-400: #98a2b3;
$grey-500: #667085;
$grey-600: #475467;
$grey-700: #344054;
$grey-800: #1d2939;
$grey-900: #101828;
$box-shadow-button-colour: #34405426;

$red-50: #fff5f5;
$red-75: #ffd6d6;
$red-100: #ffb8b8;
$red-200: #ff9999;
$red-300: #ff7a7a;
$red-400: #ff5c5c;
$red-500: #ff3d3d;
$red-600: #ff0000;
$red-700: #e00000;
$red-800: #c20000;
$red-900: #850000;

$main-blue: #0084f8;
$blue-priority: #4da0e5;
$blue-disabled: #b0d8fb;
$yellow: #ffd02e;
$main-red: #e45050;
$alert-red: #ff0000;
$error-background: #fff4f4;
$green-2fa: #63cc74;

$first-state-blue: #e9f3fc;
$second-state-blue: #d2e7f8;
$secondary-buttons-blue: #7ab8eb;

$table-footer-color: $grey-50;
$table-group-3-color: $grey-200;
$table-group-2-color: $grey-100;
$table-group-1-color: $grey-50;

//Gradient
$grey-to-clear: linear-gradient(180deg, #f9fafb00, #e5e5e5ff);
// Borders
$thin-grey-50-border: 0.1rem solid $grey-50;
$thin-grey-80-3-border: 0.1rem solid $grey-80-3;
$thin-grey-100-border: 0.1rem solid $grey-100;
$thin-grey-200-border: 0.1rem solid $grey-200;
$thin-grey-300-border: 0.1rem solid $grey-300;
$grey-300-border: 0.2rem solid $grey-300;
$thin-grey-400-border: 0.1rem solid $grey-400;
$thin-grey-500-border: 0.1rem solid $grey-500;
$thin-grey-600-border: 0.1rem solid $grey-600;

$thin-black-border: 0.1rem solid $black;
$thin-white-border: 0.1rem solid $white;

$thin-main-blue-border: 0.1rem solid $main-blue;
$thin-main-red-border: 0.1rem solid $main-red;
$thin-main-yellow-border: 0.1rem solid $main-yellow;
$thin-low-priority-border: 0.1rem solid $priority-low;

$thin-component-border: 0.1rem solid var(--primary600);
$transparent-border: 0.1rem solid $transparent;

// Input borders
$input-default-border: 0.1rem solid $grey-400;
$input-hover-border: 0.1rem solid $grey-500;
$input-active-border: 0.1rem solid $main-blue;
$input-disabled-border: 0.1rem solid $grey-200;

// Shadows
$subtle-red-box-shadow: 0 0.4rem 1rem rgba(248, 0, 0, 0.15);
$shadow-small: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.101025);
$shadow-medium: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.166242);
$shadow-large: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.100091);
$floating-actions-shadow: 0 0.4rem 2rem rgb(0 0 0 / 20%);

// Filters
$main-black-filter: brightness(0) saturate(100%) invert(15%) sepia(12%) saturate(560%)
  hue-rotate(164deg) brightness(88%) contrast(94%);
$main-blue-filter: brightness(0) invert(39%) sepia(82%) saturate(2879%) hue-rotate(188deg)
  brightness(97%) contrast(102%);
$main-grey-filter: brightness(0) saturate(100%) invert(45%) sepia(78%) saturate(68%)
  hue-rotate(166deg) brightness(88%) contrast(82%);
$main-grey-filter: brightness(0) invert(49%) sepia(10%) saturate(440%) hue-rotate(167deg)
  brightness(92%) contrast(89%);
$color-white-filter: brightness(0) invert(100%);

// Outlines
$active-state-outline: 0.3rem solid var(--primary100) !important; // Important to override the default outline rule
