@import '../variables';

a,
.link {
  color: inherit;
  cursor: pointer;

  &:hover,
  &:focus {
    color: inherit;

    text-decoration: none;
  }

  &.sw-underline {
    text-decoration: underline;
  }
}

.link-gray {
  color: $grey-300;
  cursor: pointer;
}

.link-red {
  color: $main-red;

  &:hover,
  &:active,
  &:focus {
    color: $main-red;
  }
}

.link-dashed {
  border-bottom: 0.1rem dashed $grey-300;
}

// ppLink ==> new link style
.ppLink {
  color: $main-blue;

  &:visited {
    color: $main-blue;
  }

  &:hover {
    text-decoration: underline;
    color: $main-blue;
    cursor: pointer;
  }
}

.ppLink--normal {
  font-weight: $font-reg;
}

.ppLink--bold {
  font-weight: $font-semibold;
}
