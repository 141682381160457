@use '../../../../../../styles/base/typography' as t;
@use '../../../../../../styles/generic/mixins' as m;
@import '../../../../../../styles/variables';

.actionDropdown {
  padding: $spacer-l 0 $spacer-l $spacer-l;
  display: flex;
  flex-direction: column;
  gap: $spacer-l;
}

.actionDropdown__header {
  @extend .copy;
  color: $grey-600;
  display: flex;
  align-items: center;
}

.actionDropdown__header__icon {
  margin-left: $spacer-s;
  display: inline-flex;
  color: $grey-600;

  &:hover {
    color: $tertiary-black;
  }
}

.actionDropdown__noSortingMessage {
  @extend .copy;
  color: $grey-600;
}

.actionDropdown__columnSection {
  overflow-y: auto;
  max-height: 21rem;
  padding-right: $spacer-m;
}

.actionDropdown__field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacer-s;
}

.actionDropdown__field__remove {
  width: $spacer-l;
  height: $spacer-l;
  margin-left: $spacer-m;
  cursor: pointer;
}

.actionDropdown__field__info {
  @extend .copyReg;
  color: $main-black;
  flex-shrink: 0;
  margin-right: auto;
}

.actionDropdown__field__name {
  font-size: $font-size-default;
  color: $main-black;
  margin-left: auto;
  padding-left: $spacer-m;
  font-weight: $font-semibold;
  @include m.truncate-ellipsis;
}

.actionDropdown__field__order {
  margin-left: $spacer-m;
}

.actionDropdown__field__order__arrow {
  display: block;
  height: 1.8rem;
  width: 1.8rem;
  color: var(--primary600);
  cursor: pointer;

  &:hover {
    color: var(--primary500);
  }
}

.actionDropdown__field__order__arrow--inactive {
  color: $grey-400;

  &:hover {
    color: $grey-400;
  }
}

.actionDropdown__sortByButton {
  @include m.align-center-gap($spacer-s);
  cursor: pointer;

  &:hover {
    .actionDropdown__sortByButton__text,
    .actionDropdown__sortByButton__icon {
      color: var(--primary500);
    }
  }
}

.actionDropdown__sortByButton__text {
  @extend .copy;
  color: var(--primary600);
}

.actionDropdown__sortByButton__icon {
  display: block;
  height: 1.8rem;
  width: 1.8rem;
  color: var(--primary600);
}
