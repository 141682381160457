.theme-wrapper {
  --timelineGroupWidth: 24rem;
  --timelineResizeBgColor: unset;
}

$variables: (
  --timelineGroupWidth: var(--timelineGroupWidth),
  --timelineResizeBgColor: var(--timelineResizeBgColor),
);

.timelineColumnWidth {
  width: var(--timelineGroupWidth) !important;
}
