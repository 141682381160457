/* side-panel THEME: */
.os-theme-side-panel {
  box-sizing: border-box;
  --os-size: 1rem;
  --os-padding-perpendicular: $spacer-xxs;
  --os-padding-axis: $spacer-xxs;
  --os-track-border-radius: 2.4rem;
  --os-handle-interactive-area-offset: $spacer-xs;
  --os-handle-border-radius: 2.4rem;
  --os-handle-bg: #535e63;
  --os-handle-bg-hover: #535e63;
  --os-handle-bg-active: #535e63;
}

.os-no-css-vars.os-theme-side-panel.os-scrollbar .os-scrollbar-handle {
  border-radius: 2.4rem;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar .os-scrollbar-track {
  border-radius: 2.4rem;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-horizontal {
  padding: $spacer-xxs;
  right: 1rem;
  height: 1rem;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-horizontal.os-scrollbar-rtl {
  left: 1rem;
  right: 0;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-horizontal .os-scrollbar-handle {
  min-width: $spacer-xxl;
  max-width: none;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-horizontal .os-scrollbar-handle:before {
  top: calc(($spacer-xxs + $spacer-xs) * -1);
  bottom: calc($spacer-xxs * -1);
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-vertical {
  padding: $spacer-xxs;
  bottom: 1rem;
  width: 1rem;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-vertical .os-scrollbar-handle {
  min-height: $spacer-xxl;
  max-height: none;
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: calc(($spacer-xxs + $spacer-xs) * -1);
  right: calc($spacer-xxs * -1);
}
.os-no-css-vars.os-theme-side-panel.os-scrollbar-vertical.os-scrollbar-rtl
  .os-scrollbar-handle:before {
  right: calc(($spacer-xxs + $spacer-xs) * -1);
  left: calc($spacer-xxs * -1);
}
.os-no-css-vars.os-theme-side-panel .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.44);
}
.os-no-css-vars.os-theme-side-panel:hover .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.55);
}
.os-no-css-vars.os-theme-side-panel:active .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.66);
}
