@use '../variables' as v;
@use '../generic/mixins' as m;
@use '/src/styles/base/typography';

.costInput {
  width: 100%;
  display: flex;
  flex: 1 0 0;
  min-height: v.$spacer-xxl;
  color: v.$grey-400;
  border: v.$thin-grey-400-border;
  border-radius: v.$border-radius-m;

  &:hover:not(.costInput--focused) {
    .costInput__icon-container {
      border-color: v.$grey-600;
      background-color: v.$grey-200;
    }
  }
}

.costInput--focused {
  @extend .activeStateInput;

  border-color: var(--primary600);

  .costInput__icon-container {
    border-color: var(--primary600);
    background-color: var(--primary75);
  }
}

.costInput__icon-container {
  min-height: v.$spacer-xxl;
  padding: 0.6rem $spacer-m;
  background-color: v.$grey-100;
  border-right: v.$thin-grey-400-border;
  border-radius: 0.3rem 0 0 0.3rem;
  white-space: nowrap;
}

.costInput__icon-container--border-right {
  border-right: 0;
  width: 4.5rem;
  border-left: v.$thin-grey-400-border;
  border-radius: 0 0.3rem 0.3rem 0;
}

.costInput__input {
  border-radius: v.$border-radius-m;
  padding: 0.6rem v.$spacer-s 0.6rem v.$spacer-xl;
  border: none;
  width: 100%;
  @include m.truncate-ellipsis;
  text-align: end;
  color: v.$grey-900;

  &::placeholder {
    color: v.$grey-400;
  }
}

// hide arrows on number input
.costInput__input::-webkit-inner-spin-button,
.costInput__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.costInput__input[type='number'] {
  -moz-appearance: textfield;
}
