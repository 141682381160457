@use '../../../../../../styles/variables' as v;

.actionFieldsDropdown__copy {
  padding-left: v.$spacer-l;
  color: v.$grey-600;
  font-size: v.$font-small;
}

.actionFieldsDropdown__title {
  color: v.$grey-600;
  margin-bottom: v.$spacer-m;
  padding-left: v.$spacer-l;
  font-weight: v.$font-semibold;
  font-size: v.$font-size-default;
}
