@use './table-grouping.scss';
@use './table-footer/table-footer.scss';
@use '../../../../../../styles/base/typography' as t;
@use '../../../../../../styles/generic/mixins' as m;
@import '../../../../../../styles/variables';
//
// --- --- --- customSiteTable --- --- ---
//
$table-scrollbar-width: 1em;

.table__body--grouped {
  background-color: $white;
}

.table__body--collapsed {
  width: calc(50vw - 6em);
}

.customSiteTable__text {
  margin: 0;
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $font-size-default;
  max-width: inherit;
}

.customSiteTable__text--cost {
  text-align: right;
  margin-right: 0.5em;
  letter-spacing: 0.1em;
  font-feature-settings:
    'tnum' on,
    'onum' on;

  .customSiteTable__text--inner {
    display: inline-flex;
    justify-content: flex-end;
  }
}

.customSiteTable__priority-indicator {
  width: 1.2em;
  height: 1.2em;
  margin: 0 auto;
  border-radius: $border-radius-full;
}

.customSiteTable__priority--LOW {
  background-color: $priority-low;
}

.customSiteTable__priority--MEDIUM {
  background-color: $main-yellow;
}

.customSiteTable__priority--HIGH {
  background-color: $main-red;
}

.customSiteTable--P,
.customSiteTable--S,
.customSiteTable--Priority,
.customSiteTable--Status,
.customSiteTable--ID {
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.site-table__total {
  color: $grey-500;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.1em;
  font-feature-settings:
    'tnum' on,
    'onum' on;
  font-weight: $font-semibold !important;
  font-size: 13px;
}

.site-table__sum__title {
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  flex-shrink: 0;
  cursor: pointer;
  color: $grey-500;

  &:hover {
    text-decoration: underline;
  }
}

.site-table__sum__wrapper {
  display: flex;
  margin: auto;
}

.site-table__sum__title--sorted {
  max-width: calc(100% - 1.8em);
  margin-right: 0.2em;
}

.site-table__sum__title--centered {
  margin: auto;
  display: flex;
}

.table__cell__inner__namedColumn {
  max-width: calc(100% - 3em);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal !important;
  display: flex;
}

.table__head__cell--dim {
  opacity: 0.4;
}

.table__head__cell--inner {
  font-size: 1.4em;
  line-height: 1.8em;
  font-family: inherit;
  font-weight: $font-semibold;

  width: 100%;
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  text-overflow: ellipsis;
  color: $grey-700;
  display: inline-flex;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.table__head__cell--innerSum {
  display: flex;
  justify-content: flex-end;
}

.customSiteTable__checkbox {
  margin: auto;
  border-radius: 4px;
  background-color: $grey-200;
  border: 1px solid $grey-300;
}

.customSiteTable__checkbox--selected {
  background-color: var(--primary600);
  border-color: var(--primary600);
}

.customSiteTable__checkbox__container {
  height: 3em;
  width: 3em;
  display: flex;
  border-radius: $border-radius-full;
  cursor: pointer;
}

.radial-out {
  overflow: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  transition: color 300ms;
  transform: translateZ(0);

  &:hover:before {
    transform: scale(2);
  }
}

.radial-out--header {
  &:before {
    background: $grey-300;
  }
}

.customSiteTable--attachments {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}

.customSiteTable__text--title {
  width: calc(100% - 2em);
}

.customSiteTable__text--inner {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: inline-flex;
  width: 100%;
  max-width: inherit;
}

.text--highlighted {
  background: var(--primary600);
  color: $white;
}

.table__head {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 3.6em;
  line-height: 1.8em;
  background-color: $header-color;
  border-bottom: $table-border;
}

.table__footer__row,
.table__head__row {
  display: flex;
  position: relative;
}

.table__head--columnMove {
  pointer-events: none;
}

.table__head__cell {
  width: 100%;
  height: 100%;
  line-height: 3.2em;
}

.table__head__resizeHandle {
  position: absolute;
  top: 0;
  right: -0.8em;
  z-index: 10;
  width: 1.5em;
  height: 100%;
  cursor: ew-resize;

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: 0.4em;
    height: 100%;
    transform: translateX(-50%);
    background-color: var(--primary600);
  }
}

.table__head__resizeHandle--active {
  &::after {
    display: block;
  }
}

.tableSortingElement {
  font-weight: $font-semibold;
  pointer-events: none;
  display: flex;
  color: $grey-500;
  position: absolute;
  right: 0.5em;
  align-items: center;
  justify-content: flex-end;
}

.tableSortingElement__ASC {
  transform: rotateZ(180deg);
  height: 1em;
  width: 100%;
}

.tableSortingElement__arrow {
  height: 1em;
  width: 100%;
}

.table__body {
  padding-bottom: 3.6em;
  overflow: scroll !important;
  scrollbar-width: none !important;

  .virtualScroller__dataWrapper {
    background-color: $white;
  }
}

.table__body--disabled {
  .virtualScroller__dataWrapper {
    .table__row:not(.table__row--active) {
      cursor: not-allowed !important;

      &:hover {
        background: $white !important;
      }

      .table__select {
        cursor: not-allowed !important;
        background: $white !important;

        &:hover {
          background: $white !important;
        }

        .table__cell__inner {
          .radial-out {
            cursor: not-allowed;

            &:before {
              content: '';
              transition: none;
              background: none;
            }
          }
        }
      }
    }
  }
}

.table__row__wrapper {
  height: 3.6em;
}

.table__row {
  display: flex;
  height: 3.6em;
  background-color: $white;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--primary75);

    .customSiteTable__checkbox__wrapper,
    .table__select {
      background-color: var(--primary75);
    }

    .table__columnCells {
      .table__cell {
        background-color: var(--primary75);
      }
    }
  }
}

.table__row--lastGroupRow {
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;

  & > .table__cell:first-child {
    border-bottom-left-radius: 1em;
  }

  .table__columnCells {
    .table__cell:last-child {
      border-bottom-right-radius: 1em;
    }
  }
}

.table__row--empty {
  background-color: $transparent;
  cursor: default;

  &:hover {
    background-color: $transparent;

    .table__select {
      background-color: $transparent;
    }
  }
}

.table__row--empty-3 {
  background-color: $white;

  &:hover {
    background-color: $white;

    .table__select {
      background-color: $white;
    }
  }
}

.table__row--empty-1 {
  background-color: $table-group-3-color;

  &:hover {
    background-color: $table-group-3-color;

    .table__select {
      background-color: $table-group-3-color;
    }
  }
}

.table__row--empty-2 {
  background-color: $table-group-2-color;

  &:hover {
    background-color: $table-group-2-color;

    .table__select {
      background-color: $table-group-2-color;
    }
  }
}

.table__row--header {
  border-top-left-radius: 1em;
  background-color: $table-group-2-color;
  cursor: default;

  &:hover {
    background-color: $table-group-2-color;

    .table__select {
      background-color: $table-group-2-color;
    }
  }
}

.table__row--header-3 {
  background-color: $table-group-1-color;

  &:hover {
    background-color: $table-group-1-color;

    .table__select {
      background-color: $table-group-1-color;
    }
  }
}

.table__row--header-1--border,
.table__row--header-2--border,
.table__row--header-3--border {
  box-sizing: border-box;

  border-bottom: $table-border;
}

.table__row--header-1 {
  background-color: $table-group-3-color !important;

  &:hover {
    background-color: $table-group-3-color;

    .table__select {
      background-color: $table-group-3-color;
    }
  }
}

.table__row--header-2 {
  background-color: $table-group-2-color !important;

  &:hover {
    background-color: $table-group-2-color;

    .table__select {
      background-color: $table-group-2-color;
    }
  }
}

.table_row--collapsed {
  border-bottom-left-radius: 1em;
}

.table__row.table__row--active {
  // specificity applied
  background-color: var(--primary100);
  color: $main-black;
  font-weight: $font-semibold;

  .table__select {
    background-color: var(--primary100);
  }
}

.table__row--grouped.table__row--active {
  .customSiteTable__checkbox__wrapper {
    background-color: var(--primary100);
  }
  .table__cell {
    background-color: var(--primary100);
  }
  .table__select {
    background-color: var(--primary100);
  }

  &:hover {
    .customSiteTable__checkbox__wrapper,
    .table__select {
      background-color: var(--primary75);
    }
  }
}

.table__row--selected {
  // specificity applied
  background-color: var(--primary100) !important;

  .customSiteTable__checkbox__wrapper,
  .table__select {
    background-color: var(--primary100) !important;
  }

  .table__columnCells {
    .table__cell {
      background-color: var(--primary100) !important;
    }
  }
}

.table__row--flagged {
  // specificity applied
  background-color: $red-75 !important;

  .customSiteTable__checkbox__wrapper,
  .table__select {
    background-color: $red-75 !important;
  }

  .table__columnCells {
    .table__cell {
      background-color: $red-75 !important;
    }
  }
}

.table__row.table__row--highlight {
  // specificity applied
  background-color: var(--primary75);

  .customSiteTable__checkbox__wrapper,
  .table__select {
    background-color: var(--primary75);
  }

  .table__columnCells {
    .table__cell {
      background-color: var(--primary75);
    }
  }
}

.table__columnCells {
  display: flex;
}

.table__columnCells__header {
  transform: translateX(0) !important;
  overflow: visible;
  padding-left: 1em;
  border-top: $table-border;
}

.table_header_merged {
  overflow: visible;
  display: flex;
  position: sticky;
  left: 0;
  z-index: 10;
  justify-content: flex-start;
  width: 4em;
  box-sizing: content-box;
}

.table_header_merged--collapsed {
  border-bottom-left-radius: 1em;
}

.table_header_merged--level-1 {
  background-color: $white;
}

.table_header_merged--level-2 {
  background-color: $table-group-3-color;
  border-left: 0.1em solid transparent;
}

.table__columnCells--grouped--1 {
  @media screen and (max-width: 1800px) {
    padding-left: 16px !important;
  }
}

.table__columnCells--grouped--1,
.table__columnCells--grouped--2 {
  border-bottom: $table-border;
  height: 3.5em;
}

.table_header_merged--level-3 {
  background-color: $table-group-2-color;
}

.table__cell__groupedHeader--last {
  border-top-right-radius: 1em;
  border-right: $table-border;
}

.table__headerCell {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  color: $grey-500;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table__pointCount {
  font-size: 1.2em;
  background-color: $grey-300;
  color: $grey-700;
  margin-left: 1.2em;
  padding: 0.5em 1.2em;
  border-radius: 10em;
  display: inline-flex;
  position: relative;

  &:hover {
    .table__headerOptions {
      visibility: visible;
    }

    .table__pointCount__count {
      visibility: hidden;
    }
  }
}

.table__headerName {
  font-size: 1.4em;
  line-height: 1.8em;
  font-family: inherit;
  font-weight: $font-semibold;

  overflow: hidden;
  text-overflow: ellipsis;
  color: $grey-500;
}

.table__headerName--1 {
  max-width: calc(100vw - 20rem);

  @include mobile-and-down {
    max-width: calc(100vw - 14rem);
  }
}

.table__headerName--2 {
  max-width: calc(100vw - 22rem);

  @include mobile-and-down {
    max-width: calc(100vw - 16rem);
  }
}

.table__headerName--3 {
  max-width: calc(100vw - 24rem);

  @include mobile-and-down {
    max-width: calc(100vw - 18rem);
  }
}

.table__shadowColumn {
  position: absolute;
  z-index: 110;
  opacity: 0.1;
  background-color: var(--primary600);
  pointer-events: none;
}

.table__columnPlacementIndicator {
  position: absolute;
  z-index: 115;
  width: 0.3em;
  background-color: var(--primary600);
  pointer-events: none;
}

.table__columnPlacementIndicator--grouping {
  margin-left: 0.5em !important;
}

.table__cell__inner--noPadding {
  padding: 0 1em 0 0 !important;
}

.table__head__cell--inner--NoPadding {
  padding-left: 0.5em;
}

.table__group__cell--inner--NoPadding {
  // padding-right: 20px;
  padding-left: 0;
}

.table__cell {
  @include m.flex-center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  border-right: $table-border;
  border-bottom: $table-border;
  color: $grey-700;
}

.table__cell__head {
  @include m.flex-center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  border-right: $table-border;
  color: $main-black;
}

.table__cell--dim {
  background-color: $table-group-2-color;

  & > .table__cell__inner {
    opacity: 0.4;
  }
}

.table__cell__inner {
  padding: 0 0.4em 0 0.8em;
  white-space: nowrap;
  user-select: none;
  display: grid;
  align-items: center;
  gap: $spacer-s;
}

.table__cell__inner__namedColumn--integration {
  grid-template-columns: 13px auto;
}

.table__select__inner {
  overflow: visible;
  position: relative;
}

.table__select__inner--third {
  left: 2.3em;
}

.table__select__inner--second {
  left: 1.6em;
}

.table__select__inner--first {
  left: 0.7em;
  @media only screen and (min-width: 112.5em) {
    left: 0.9em;
  }
}

.table__row--grouping--last-third {
  left: -1.5em;
  border-left: 0.1em solid #d0d5dd;
  width: 1.5em !important;
}

.table__arrow {
  position: sticky;
  left: 0;
  z-index: 10;
  border-right: none;
  border-bottom: none;
  border-radius: 1em 0 0 0;
  border-top: $table-border;
  border-left: $table-border;
}

.table__arrow--expand {
  border-bottom: $table-border;
  border-bottom-left-radius: 1em;
  height: 3.6em;
}

.table__select {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: $white;
}

.table__select--grouping {
  margin-left: 1.5em;
  border-left: $table-border;
}

.table__head__select {
  z-index: 100;
  width: 4em;
  background-color: $header-color;
}

.table__footer__select {
  z-index: 100;
  width: 4em;
  background: none;
}

.table__avatar__container,
.table__tags__container {
  @include m.truncate-ellipsis;
  display: flex;
  gap: 0.2em;
}

.table__avatar {
  height: 2.6em;
  border-radius: $border-radius-full;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: 0.8em;
  }

  &:not(.table__avatar--highlight) {
    &:first-child {
      margin-left: 0.3em !important;
    }
  }
}

.table__avatar--highlight {
  box-sizing: border-box;
  border: 0.2em solid var(--primary600);
  padding: 0.1em;

  &:not(:first-child) {
    margin-left: -0.3em;
  }

  &:first-child {
    margin-right: 0.5em;
  }
}

.table__checkboxCF {
  margin: auto;
  display: block;
  height: 1em;
  width: 1.2em;
}

.table__avatar--disabled {
  opacity: 0.5;
}

.table__row--grouped {
  background-color: $table-group-1-color;
}

.table__headerCell--options {
  display: flex;
  margin-right: 1.2em;
}

.table__footer {
  transform: translateY(calc(-2.8em - $table-scrollbar-width));
  background: $table-footer-color;
  border-top: $table-border;
  box-sizing: border-box;
  border-bottom: 0.1em solid $table-footer-color;
  height: 2.8em;
}

.table__columnCells--group1 {
  background: $white;
}

.table__columnCells--group2 {
  background: $table-group-3-color;
}

.table__columnCells--group3 {
  background: $table-group-2-color;
}

.table__cell--group {
  background: $table-group-1-color;
  font-size: 1em;
}

.timeline__body {
  padding-bottom: 1em;
}

._groupDropdown__field__order {
  width: 2.4em;
  height: 2.4em;
  display: block;
  cursor: pointer;
}

.table__groupLevelIndicator--1--2 {
  width: 1.6em;
  background: $white;
  left: -0.1em;
}

.table__groupLevelIndicator--2--2 {
  width: 1.6em;
  background: $grey-200;
  left: 1.5em;
  border-left: $table-border;
}

.table__groupLevelIndicator--1--3 {
  width: 1.6em;
  background: $grey-200;
  left: 1.5em;
  border-left: $table-border;
}

.table__groupLevelIndicator--2--3 {
  width: 1.6em;
  background: $white;
  left: 0;
}

.table__groupLevelIndicator--3--3 {
  width: 2.5em;
  background: $grey-100;
  left: 3.3em;
  border-left: $table-border;
  box-sizing: border-box;
}

.table_flag_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
