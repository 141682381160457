@use '../variables' as v;

.no-margin {
  margin: 0;
}

.no-margin--top {
  margin-top: 0;
}

.no-margin--bottom {
  margin-bottom: 0;
}

// Icon spacing, Button spacing
.icon-gap--left,
.button-gap--left {
  margin-left: v.$spacer-xs;
}

.icon-gap--left2x,
.button-gap--left2x,
.gap-left--m {
  margin-left: v.$spacer-s;
}

.icon-gap--right,
.button-gap--right {
  margin-right: v.$spacer-xs;
}

.icon-gap--right2x,
.button-gap--right2x,
.gap-right--m {
  margin-right: v.$spacer-s;
}
