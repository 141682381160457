@import '../variables.scss';
@import './quill.scss';

.dashletFlip__container {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 800px;
}

#dashlet-flip__card {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform-origin: right center;
}

#dashlet-flip__card .dashlet-flip__content-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  transform: translateZ(0);
  backface-visibility: hidden;
}

#dashlet-flip__card .dashletFlip__front {
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
}

#dashlet-flip__card .dashletFlip__back {
  z-index: 9;
  border-radius: $border-radius-l;
  transform: rotateY(180deg);
}

#dashlet-flip__card.flipped {
  transform: translateX(-100%) rotateY(-180deg);
  pointer-events: none;

  .dashlet-flip__content-wrapper {
    backface-visibility: visible;
    position: absolute;
  }
}
.dashletFlip__front {
  .dashletHead__back {
    display: none !important;
  }
}

.dashletFlip__back {
  .dashletHead__front {
    display: none !important;
  }

  .dashletFlip__container {
    overflow: hidden !important;
    pointer-events: none;
  }
}

.handle-w,
.handle-e {
  cursor: col-resize !important;
}

.handle-n,
.handle-s {
  cursor: row-resize !important;
}
