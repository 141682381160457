.m {
  &--0 {
    margin: 0;
  }
  &--05 {
    margin: $spacer-xs;
  }
}

.mb {
  &--05 {
    margin-bottom: $spacer-xs;
  }
  &--2 {
    margin-bottom: $spacer-xl;
  }
}

.flex {
  display: flex;
}
