@use '../../../../../../../../../styles/base/typography' as t;
@use '../../../../../../../../../styles/variables' as v;
@use '../../../../../../../components/dropdown/Dropdown.scss' as *;

._groupByDropdown__title {
  @extend .copy;
  color: v.$grey-600;
  margin-bottom: v.$spacer-m;
  padding: 0 0 0 v.$spacer-l;
}
