@use '/src/styles/base/typography';
@import '../../styles/variables.scss';
/*

Types of inputs:

.input.input-border
.input.input-border-bottom
.input.text-md.input-border.input-border-radius
.input.text-md.input-border-bottom
.input.input-lg.input-border-bottom

*/

// basic elements

form {
  margin: 0;
}

.input-field-description {
  @extend .copySmall;

  font-weight: $font-thin;
  padding-left: 0.1rem;
  color: $grey-300;
}

.g-input--clear {
  margin: 0;
  padding: 0;
  border: 0;

  &:focus {
    border: 0;
  }
}

input,
textarea {
  font-family: var(--fontFamily), sans-serif;
  background: $white;
}

textarea {
  resize: vertical;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 $spacer-xxl white inset; // Removes yellow background from inputs when input is autofilled in Chrome.
}

.form-row {
  margin-bottom: $spacer-xl;
}

// Bootstrap
.input-group {
  // custom classes
  &.inputGroup__icon {
    width: 100%;

    .sw-icon {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
    }

    .form-control {
      padding-left: $spacer-xl;
    }

    .input-border:focus {
      padding-left: calc(2.1em - 0.1rem);
    }
  }

  .filterContent__input-group-addon {
    border: 0;
    background: $white;
  }
}

// custom classes
.input {
  padding: $spacer-xs;
  border-radius: 0;
  background: $white;
}

.input-border-bottom {
  margin-top: $spacer-xxs;
  padding-bottom: 0;
  padding-left: 0;
  border: 0;
  border-bottom: $thin-grey-300-border;
  font-size: $font-medium-large;
  font-weight: $font-reg;

  &:focus,
  &:hover {
    margin-bottom: -0.1rem;
    border-width: $spacer-xxs;
    border-color: var(--primary600);
    box-shadow: none;
  }

  &.input-inline {
    display: inline-block;
    width: calc(100% - $spacer-l); // 100% - label right margin
  }
}

// Custom checkbox START

.control-custom {
  @extend .control-custom--no-margin;

  margin-right: $spacer-m;
  margin-bottom: $spacer-s;
}

.control-custom--no-margin {
  @extend .copyReg;

  display: inline-block;
  position: relative;
  box-sizing: border-box;
  // height: $spacer-xl;
  padding-right: $spacer-s;
  color: $grey-500;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;

  font-weight: $font-thin;

  .sw-icon {
    font-size: 1.3rem;
    line-height: $spacer-m;
  }
}

.control-custom-separator:not(:last-child) {
  border-right: 0.1rem solid #e1e4e5;
}

.control-custom input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control-custom__indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: $spacer-xl;
  height: $spacer-xl;
  border: $thin-grey-400-border;
  border-radius: $border-radius-s;
  background: none;
}

.control-custom__indicator--sm {
  width: $spacer-l;
  height: $spacer-l;
}

/* Hover and focus states */
.control-custom:hover input ~ .control-custom__indicator,
.control-custom input:focus ~ .control-custom__indicator {
  background: none;
}

/* Checked state */
.control-custom input:checked ~ .control-custom__indicator {
  background: none;
}

/* Hover state whilst checked */
.control-custom:hover input:not([disabled]):checked ~ .control-custom__indicator,
.control-custom input:checked:focus ~ .control-custom__indicator {
  background: none;
}

/* Disabled state */
.control-custom input:disabled ~ .control-custom__indicator {
  opacity: 0.6;
  background: $grey-300;

  pointer-events: none;
}

/* Check mark */
.control-custom__indicator:after {
  content: '';
  display: none;
  position: absolute;
}

/* Show check mark */
.control-custom input:checked ~ .control-custom__indicator:after {
  display: block;
}

/* Checkbox tick */
.control-custom--checkbox .control-custom__indicator:after {
  top: 1%;
  left: 28%;
  width: $spacer-s;
  height: $spacer-m;
  border: solid #657277;
  border-width: 0 $spacer-xxs $spacer-xxs 0;
  transform: rotate(38deg);

  -webkit-transform: rotate(38deg);
}

.control-custom--checkbox .control-custom__indicator--sm:after {
  top: 5%;
  left: 31%;
  width: $spacer-xs;
  height: $spacer-s;
}

/* Disabled tick colour */
.control-custom--checkbox input:disabled ~ .control-custom__indicator:after {
  border-color: $checkbox-border;
}

// High priority checkboox
.control-custom.high,
.control-custom.HIGH {
  color: $main-red;

  .control-custom__indicator {
    border-color: $main-red;

    &:after {
      border-color: $main-red;
    }
  }
}

// Medium priority checkbox
.control-custom.medium,
.control-custom.MEDIUM {
  color: $main-yellow;

  .control-custom__indicator {
    border-color: $main-yellow;

    &:after {
      border-color: $main-yellow;
    }
  }
}

// Low priority checkbox
.control-custom.low,
.control-custom.LOW {
  color: $main-blue;

  .control-custom__indicator {
    border-color: $main-blue;

    &:after {
      border-color: $main-blue;
    }
  }
}

// Radio label
.controlCustom--no-height {
  height: unset !important;
}

.control-custom--radio {
  // State indicator box
  .control-custom__indicator {
    border-radius: $border-radius-full;
    width: $spacer-l;
    height: $spacer-l;
  }
}

/* Radio button inner circle */
.control-custom--radio .control-custom__indicator:after {
  top: 23%;
  left: 23%;
  width: $spacer-s;
  height: $spacer-s;
  border-radius: $border-radius-full;
  background: $grey-400;
}

/* Disabled circle colour */
.control-custom--radio input:disabled ~ .control-custom__indicator:after {
  background: $checkbox-border;
}

.list-items-container {
  margin-left: -$spacer-xxxl;
}

// Select
.custom-select {
  height: 1.9em;
  border-color: $grey-300;
  background-color: $white;

  &:focus {
    border-color: $grey-300;
  }
}

.point-id {
  display: inline-block;
  width: 5rem;
}

.with-point-id {
  display: inline-block;
  width: -webkit-calc(100% - 6rem);
  width: -moz-calc(100% - 6rem);
  width: calc(100% - 6rem);
}

//
// Slider
//
// Used in: profile settings

.slider__container {
  display: flex;
  align-items: center;
}

#zoom-factor {
  $thumb-color: $white;
  $thumb-border-hover: $grey-400;
  $thumb-border-color: $grey-300;
  $slider-bg: $white;
  $slider-border: $grey-300;
  $slider-height: $spacer-s;

  #slider {
    display: inline-block;
    width: 100%;
    min-width: 20rem;
    padding-right: $spacer-xl;
    border-bottom: none;
    box-sizing: border-box;

    @include tablet-and-down {
      min-width: unset;
    }
  }

  input[type='range'] {
    height: $spacer-xl;
    margin-top: $spacer-m;
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: $slider-height;
    border: 0.1rem solid $slider-border;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    background: $slider-bg;
    cursor: pointer;
  }
  input[type='range']::-webkit-slider-thumb {
    width: $spacer-l;
    height: $spacer-l;
    margin-top: -0.6rem;
    border: 0.1rem solid $thumb-border-color;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    // border-radius: 2.4rem;
    background: $thumb-color;
    cursor: pointer;

    -webkit-appearance: none;

    &:hover {
      border: 0.1rem solid $thumb-border-hover;
    }
  }
  input[type='range']:focus::-webkit-slider-runnable-track {
    background: $slider-bg;
  }
  input[type='range']::-moz-range-track {
    width: 100%;
    height: $slider-height;
    border: 0.1rem solid $slider-border;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    background: $slider-bg;
    cursor: pointer;
  }
  input[type='range']::-moz-range-thumb {
    width: $spacer-l;
    height: $spacer-l;
    border: 0.1rem solid $thumb-border-color;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    background: $thumb-color;
    cursor: pointer;

    &:hover {
      border: 0.1rem solid $thumb-border-hover;
    }
  }
  input[type='range']::-ms-track {
    width: 100%;
    height: $slider-height;
    border-color: $transparent;
    color: $transparent;
    background: $transparent;
    cursor: pointer;
  }
  input[type='range']::-ms-fill-lower {
    border: 0.1rem solid $slider-border;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    background: $slider-bg;
  }
  input[type='range']::-ms-fill-upper {
    border: 0.1rem solid $slider-border;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    background: $slider-bg;
  }
  input[type='range']::-ms-thumb {
    width: $spacer-l;
    height: $spacer-l;
    margin-top: 0.1rem;
    border: 0.1rem solid $thumb-border-color;
    border-radius: $border-radius-s;
    box-shadow: 0 0 0 #000;
    background: $thumb-color;
    cursor: pointer;

    &:hover {
      border: 0.1rem solid $thumb-border-hover;
    }
  }
  input[type='range']:focus::-ms-fill-lower {
    background: $slider-bg;
  }
  input[type='range']:focus::-ms-fill-upper {
    background: $slider-bg;
  }
}
