@use '../base/typography' as t;
@use '../variables.scss' as v;
@use '../generic/mixins' as m;

// https://github.com/quilljs/quill/issues/1374#issuecomment-520296215
.ql-clipboard {
  position: fixed !important;
  opacity: 0 !important;
  left: 50% !important;
  top: 50% !important;
}

.ql-editor {
  font-size: v.$font-size-default;
  font-weight: v.$font-reg;
  line-height: 2rem;
  padding: v.$spacer-s v.$spacer-s 0 v.$spacer-s;
  border-radius: v.$spacer-xs;
  color: v.$grey-900;
  font-family: var(--fontFamily), serif;
  background: v.$white;

  ol,
  ul {
    padding-left: v.$spacer-xs;
  }
}

.ql-toolbar.ql-snow {
  padding: v.$spacer-xs v.$spacer-s v.$spacer-s v.$spacer-s;
}

.ql-editor.ql-blank::before {
  font-size: v.$font-size-default;
  color: v.$grey-400;
  font-weight: v.$font-thin;
  left: v.$spacer-s;
  font-style: initial;
}

.ql-tooltip {
  position: sticky !important;
  z-index: 500;
}

.mention {
  background-color: v.$first-state-blue;
  border-radius: v.$border-radius-m;
  color: v.$main-blue;
  padding: 0 v.$spacer-xs 0 v.$spacer-xs;
  font-weight: v.$font-reg;
}

.ql-mention-list-container {
  z-index: v.$z-index-dropdown !important;
  padding-left: 0;
  border: 0.1rem solid v.$grey-200;
  border-radius: v.$border-radius-m;
  box-shadow: 0 v.$spacer-xxs v.$spacer-s v.$black-25;
  background: v.$white;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 40rem;
  scrollbar-color: v.$grey-400 v.$transparent;
  scrollbar-width: thin;

  @include mobile-and-down {
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
  }
}

.ql-mention-list {
  background-color: v.$white;
  padding-left: 0;
  padding-top: v.$spacer-s;
  max-width: 45rem;

  .selected {
    background-color: unset !important;
  }
}

.ql-toolbar {
  button {
    @include mobile-and-down {
      width: v.$spacer-l !important;
      padding: v.$spacer-xxs 0 !important;
    }
  }
}

.user__avatar {
  height: v.$spacer-xxl;
  border-radius: v.$border-radius-full;
  background: v.$white;
  cursor: pointer;
  margin-left: v.$spacer-s;
}

.users__select-username {
  @include m.truncate-ellipsis;
  line-height: v.$spacer-l;
  color: v.$grey-600;
  font-size: v.$font-size-default;
}

.users__select-user-email {
  @extend .copySmall;
  text-overflow: ellipsis;
  max-width: inherit;
  overflow: hidden;
  color: v.$grey-400;
  font-weight: v.$font-thin;
  font-size: v.$font-small;
}

.users__item {
  background-color: v.$white;
  cursor: pointer;
  width: calc(100% - v.$spacer-xl);
  display: flex;
  line-height: v.$spacer-xl;
  border-radius: v.$border-radius-s;
  height: v.$spacer-xxl;
  padding-top: v.$spacer-xxs;
  padding-bottom: v.$spacer-xs;
  margin: v.$spacer-s;
  margin-top: 0;
  border: 0;
  @include m.truncate-ellipsis;

  &:hover,
  &:focus {
    background: v.$grey-200;
    cursor: pointer;
  }
}

.users__select-details {
  padding-left: v.$spacer-l;
  max-width: 36rem;
}
