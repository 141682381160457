@use '../variables' as v;
@use '../generic/mixins' as m;
@use '/src/styles/base/typography';

.select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: v.$spacer-xs v.$spacer-s v.$spacer-xs v.$spacer-m;
  border-radius: v.$border-radius-m;
  min-height: v.$spacer-xxl;
  color: v.$grey-900;
  background-color: v.$white;
  cursor: pointer;

  &:hover,
  &:focus {
    .select__caret {
      visibility: visible;
    }
  }

  &:not(.select--inputFocused):not(.select--disabled) {
    border: v.$thin-grey-400-border;
  }

  &:hover {
    border-color: v.$grey-600;
  }
}

.select--inputFocused {
  @extend .activeStateInput;
}

.select--w100 {
  width: 100%;
}

.advancedFilter__option--long {
  width: 28rem;
  height: 3.2rem;
}

.select--disabled {
  border: $thin-grey-300-border;
  background: $grey-100;
  cursor: default;

  &:hover {
    border-color: $grey-300;
  }
}
