.row.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > [class^='col-'],
  > [class*=' col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
.padding-right-20 {
  padding-right: $spacer-xl;
}

.dropdown-backdrop {
  z-index: 10 !important; //Fixing the issue with unclickable dropdowns.
}
