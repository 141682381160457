// This file is being used for special button conditions
@use '../variables' as v;
@use '../generic/mixins' as m;

#newPointBtn {
  padding-left: v.$spacer-m;

  .button__text {
    display: flex;
    align-items: center;
    gap: v.$spacer-xs;
    text-wrap: nowrap;
  }
}

.settingsTable__content-right--buttons {
  .buttonSecondary {
    width: 2.4rem;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dashletFlip__footer {
  .deleteButtonWrapper {
    width: v.$button-large-height !important;
    height: v.$button-large-height !important;
  }
}

#savedViews__header-button {
  button:not(.buttonText--disabled) {
    &:hover {
      cursor: pointer;
    }
    .button__text {
      &:hover {
        color: var(--primary500);
      }
    }
  }

  .button__text {
    color: v.$grey-500;
  }
}

.savedViews__active-tab {
  #savedViews__header-button {
    button {
      &:hover {
        cursor: default;
      }
    }

    .button__text {
      color: var(--primary600);

      &:hover {
        color: var(--primary600) !important;
      }
    }
  }
}

#logInBtn {
  @include m.flex-center;
  width: 100%;
}
