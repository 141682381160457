@use '../variables' as v;

html {
  // 1 rem = 10px
  font-size: 62.5%;
}

body {
  color: v.$grey-800;
  font-family: var(--fontFamily), sans-serif;
}

p {
  font-size: v.$font-size-default;
}

strong {
  font-weight: $font-semibold;
}

h2 {
  font-size: 2.6rem;
  line-height: 3.6rem;
  font-family: var(--fontFamily), serif;
  font-weight: v.$font-semibold;
}

h3 {
  font-size: v.$font-size-large;
  line-height: 3rem;
  font-family: var(--fontFamily), serif;
  font-weight: v.$font-semibold;
}

h4 {
  font-size: $font-header;
  line-height: 2.6rem;
  font-family: var(--fontFamily), serif;
  font-weight: v.$font-semibold;
}

h5 {
  font-size: v.$font-medium-large;
  line-height: v.$spacer-xl;
  font-family: var(--fontFamily), serif;
  font-weight: v.$font-semibold;
}

h6 {
  font-size: v.$font-medium;
  line-height: 2rem;
  font-family: var(--fontFamily), serif;
  font-weight: v.$font-semibold;
}

.word-break {
  word-break: break-word;
}

button {
  background-color: transparent;
  border: 0;
  font-family: var(--fontFamily), serif;
  position: relative;
}
