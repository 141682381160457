@use '../../../../../../../styles/base/typography' as t;
@use '../../../../../../../styles/variables' as *;
@use '../../../../../../../styles/generic/mixins' as m;

.table__cell__footer {
  @extend .siteTableFont;

  @include m.flex-center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  color: $grey-500;
}

.table__cell__footer--showTotal {
  border-right: 0.1em solid $grey-300;
  border-left: 0.1em solid $grey-300;
  box-sizing: border-box;
}

.table__footer__totalPoints {
  font-size: 1.4em;
  font-family: inherit;
  font-weight: $font-semibold;
  position: absolute;
  left: 2em;
  background: $table-footer-color;
  top: -0.2em;
}
