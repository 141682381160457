@import '../../variables';

.defects-view {
  .btn.btn-border-gray {
    margin-top: $spacer-m;
    padding: $spacer-xs $spacer-s;
    border-color: $panel-font;
    color: $panel-font;
    text-align: left;

    &:hover,
    &:focus {
      border-color: $white;
      color: $white;
    }
  }
}
