@import '../variables';

.sw-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background: $black-50;

  .text-lg {
    position: absolute;
    color: $white;
  }
}

.sw-loader-spinner {
  position: relative;
  width: $spacer-xxl;
  height: $spacer-xxl;
  margin: -$spacer-l 0 0 (-$spacer-xxxl);
  border-radius: $border-radius-full;
  border: $spacer-xxs solid $transparent;
  border-top-color: $main-yellow;
  animation: spinspin 1.5s linear infinite;

  &::after,
  &::before {
    content: '';
    position: absolute;
    border: $spacer-xxs solid $transparent;
    border-radius: $border-radius-full;
  }

  &::after {
    top: $spacer-xxs;
    right: $spacer-xxs;
    bottom: $spacer-xxs;
    left: $spacer-xxs;
    border-top-color: $main-red;
    animation: spinspin 2s linear infinite;
  }

  &::before {
    top: -$spacer-s;
    right: -$spacer-s;
    bottom: -$spacer-s;
    left: -$spacer-s;
    border-top-color: $main-blue;
    animation: spinspin 2.5s linear infinite;
  }
}

.sw-progress-wrap {
  position: absolute;
  top: 5rem;
  display: block;
  width: 20rem;
  height: $spacer-xl;
  overflow: hidden;
  border: $thin-main-blue-border;
  z-index: 1;

  .sw-progress-number {
    position: absolute;
    display: block;
    z-index: 2;
    width: 100%;
    color: $white;
    text-align: center;
  }

  .sw-progress-bar {
    position: absolute;
    top: 0;
    display: block;
    z-index: 1;
    width: 0;
    height: $spacer-xl;
    background: $main-blue;
  }
}

//Keyframes

@keyframes spinspin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
