@use '../../../../styles/variables' as v;

.dropdown {
  position: absolute;
  top: 100%;
  z-index: v.$z-index-dropdown;
  min-width: 10rem;
  max-height: 40rem;
  margin-top: v.$spacer-xs;
  padding: v.$spacer-s 0;
  border: v.$thin-grey-200-border;
  border-radius: v.$border-radius-m;
  box-shadow: 0 v.$spacer-xxs v.$spacer-s v.$black-25;
  background-color: v.$white;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: v.$grey-400 v.$transparent;
  scrollbar-width: thin;

  ::ng-deep {
    .dropdownWrapper {
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-color: v.$grey-400 v.$transparent;
      scrollbar-width: thin;
    }

    .dropdownWrapper--padding {
      margin: 0;
      padding: v.$spacer-s 0;
    }

    .dropdownItem--disabled {
      opacity: 0.6;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  @include v.mobile-and-down {
    top: 50% !important;
    left: v.$spacer-s !important;
    min-width: calc(100% - 2rem);
    max-height: calc(100% - 2rem - 2rem);
    max-width: calc(100% - 2rem);
    transform: translateY(-50%) !important;
    inset: 50% auto auto v.$spacer-s !important;
  }

  &--hidden {
    visibility: hidden;
    top: 0 !important;
    position: absolute !important;
  }

  &--full-width {
    width: 100%;
    max-height: max-content;
  }

  &--left-align {
    left: 0;
  }

  &--right-align {
    right: 0;
  }

  &--long {
    max-height: 45rem;
  }

  &List {
    margin: 0;
    padding: 0;
  }

  &CloseBtn {
    position: absolute;
    top: 0;
    right: v.$spacer-xs;
    color: v.$grey-400;
  }

  &Wrapper {
    margin: v.$spacer-xs 0;
    padding: 0;

    &--padding {
      margin: 0;
      padding: v.$spacer-xs 0;
    }
  }

  &Item {
    font-size: v.$font-size-default;
    white-space: nowrap;
    font-weight: v.$font-semibold;
    align-items: center;
    box-sizing: border-box;
    padding: v.$spacer-xs v.$spacer-m;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    margin-left: v.$spacer-s;
    margin-right: v.$spacer-s;
    gap: v.$spacer-xs;
    word-break: break-word;
    border-radius: v.$border-radius-m;
    color: v.$secondary-black;

    @include v.mobile-and-down {
      padding: v.$spacer-s !important;
    }

    h6 {
      font-weight: v.$font-reg;
    }

    a {
      text-decoration: none;
    }

    &:hover {
      background-color: v.$grey-200;
    }
  }

  &Item--red {
    color: v.$main-red;
  }
}

.dropdownItem__group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: v.$spacer-s;
  flex: none;
  order: 1;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 0;
}

.dropdownItem__groupName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconElementWrapper {
  display: flex;
}

.dropdownItem--disabled {
  opacity: 0.4;
  cursor: not-allowed !important;

  &:hover,
  &:focus {
    background-color: unset;
  }
}

.dropdown__searchBar {
  display: block;
  padding: 0 v.$spacer-m;
  box-sizing: border-box;
}
