@import '../variables';

.svgContent {
  width: 100%;
  height: 100%;
}

.displayContents {
  display: contents;
}

.icon--inherit {
  color: inherit;
}

.icon--inherit--hover {
  &:hover {
    color: inherit;
  }
}

.icon--white {
  color: $white;
}

.icon--red {
  color: $main-red;
}

.icon--grey-100 {
  color: $grey-100;
}

.icon--grey-200 {
  color: $grey-200;
}

.icon--grey-300 {
  color: $grey-300;
}

.icon--grey-400 {
  color: $grey-400;
}

.icon--grey-500 {
  color: $grey-500;
}

.icon--grey-600 {
  color: $grey-600;
}

.icon--grey-700 {
  color: $grey-700;
}

.icon--grey-900 {
  color: $grey-900;
}

.listCF__button:hover .icon--primary-700--hover {
  color: var(--primary700);
}

.icon--primary-400 {
  color: var(--primary400);
}

.icon--primary-400--hover {
  &:hover {
    color: var(--primary400);
  }
}

.icon--primary-500 {
  color: var(--primary500);
}

.icon--primary-500--hover {
  &:hover {
    color: var(--primary500);
  }
}

.icon--primary-600 {
  color: var(--primary600);
}

.icon--primary-600--hover {
  &:hover {
    color: var(--primary600);
  }
}

.icon--primary-700 {
  color: var(--primary700);
}

.icon--primary-700--hover {
  &:hover {
    color: var(--primary700);
  }
}

.icon--white--hover {
  &:hover {
    color: $white;
  }
}

.icon-grey-100--hover {
  &:hover {
    color: $grey-100;
  }
}

.icon--grey-200--hover {
  &:hover {
    color: $grey-200;
  }
}

.icon-grey-300--hover {
  &:hover {
    color: $grey-300;
  }
}

.icon--grey-400--hover {
  &:hover {
    color: $grey-400;
  }
}

.icon--grey--500-hover {
  &:hover {
    color: $grey-500;
  }
}

.icon--grey-600--hover {
  &:hover {
    color: $grey-600;
  }
}

.icon--grey-700--hover {
  &:hover {
    color: $grey-700;
  }
}

.icon--grey-900--hover {
  &:hover {
    color: $grey-900;
  }
}
