@import '../variables';

.no-transition {
  transition: none !important;
}

.sw-space-between {
  display: flex;
  justify-content: space-between;
}

.reset-margin,
input[type='checkbox'].reset-margin,
input[type='radio'].reset-margin {
  margin: 0;
}

.resetList {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

// TODO move to a separate file
.global__align {
  display: flex;
}

.global__align--gap {
  justify-content: space-between;
}

.global__align--self-center {
  align-self: center;
}

.global__space--top-sm {
  margin-top: $spacer-l;
}

.global__space--right {
  margin-right: $spacer-s;
}

.global__space--bottom {
  margin-bottom: $spacer-xxl;
}

.global__space--bottom-xxs {
  margin-bottom: $spacer-xs;
}

.global__space--bottom-xs {
  margin-bottom: $spacer-s;
}

.global__space--bottom-sm {
  margin-bottom: $spacer-l;
}

.global__space--bottom-lg {
  margin-bottom: 4.5rem;
}

.global__list--reset {
  padding-left: 0;
}

.global__select--reset {
  border: 0;
  background: $transparent;
}

.global__select--line {
  border-bottom: $thin-grey-300-border;
}

.fix-width-auto {
  width: auto !important;
}

.emptyElement {
  height: 0;
  position: relative;
  display: block;
}
