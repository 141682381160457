@use '../../../../../styles/base/typography';
@use '../../../../../styles/variables' as *;
@use '../../../../../styles/generic/mixins' as m;

// This is CSS for custom account list in side panel that doesn't use Angular components

$sidePanel__right__bottomMarginHeight: $spacer-xl;

.mainNav__name {
  transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
}

.siteNav__element-new-site,
.folderNav__element,
.accountNav__element,
.siteNav__element-empty-folder,
.site-nav__element {
  position: relative;
}

.mainNav__options {
  display: flex;
  margin-left: auto;
}

.mainNav__option {
  border-radius: $border-radius-m;
  color: $white;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}

.accountNav__element {
  position: relative;
  margin-right: $spacer-s;
  margin-left: $spacer-s;
  padding: 0 $spacer-s;
  color: $grey-500;
  cursor: pointer;

  &:hover {
    .mainNav__option--account {
      &:hover {
        color: $white-60;
      }
    }
  }
}

.folderNav__element {
  position: relative;
  margin-right: $spacer-s;
  margin-left: $spacer-xxl;
  padding: 0 $spacer-s;
  color: $grey-500;
  cursor: pointer;

  &:hover {
    .mainNav__option--account {
      &:hover {
        color: $white-60;
      }
    }
  }
}

.folderNav__element--expanded,
.accountNav__element--expanded {
  padding: 0 0 $spacer-s $spacer-s;
}

.folderNav__element-head,
.accountNav__element-head {
  display: flex;
  align-items: center;
  height: $spacer-xxl;
}

.account-nav__arrow {
  @include m.flex-center;
  flex-shrink: 0;
  width: $spacer-m;
}

.account-nav__arrow--unsubscribed {
  filter: $main-black-filter;
}

.folderNav__element-head--collapsed,
.accountNav__element-head--collapsed {
  border-radius: $border-radius-l !important;
  background: none;
  padding-left: $spacer-s;
}

.accountNav__account-name {
  display: inline-flex;
  position: relative;
  max-width: calc(100% - $spacer-xxxl);
  margin-left: 0.85rem;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.folder-nav__folder-name {
  display: inline-flex;
  position: relative;
  max-width: calc(100% - $spacer-xxxl);
  margin-left: 0.85rem;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    cursor: pointer;
  }
}

.folder-nav__line {
  position: absolute;
  width: 26.6rem;
  border-bottom: $spacer-xxs solid var(--primary600);
  bottom: $spacer-l;
  visibility: hidden;
}

.folder-nav__folder-input {
  display: inline-flex;
  position: relative;
  max-width: calc(100% - $spacer-xxxl);
  margin-left: 0.85rem;
  overflow: hidden;
  background: var(--panelAccent);
  box-shadow: 0 $spacer-xs $spacer-xl rgb(0 0 0 / 40%);
  color: $white;
  border: $thin-component-border;
  height: $spacer-xl;
  border-radius: $border-radius-m;
  width: 17.5rem !important;
  padding-left: $spacer-xs;
  padding-right: $spacer-xl;

  &:focus,
  &:active,
  &:focus-visible {
    border: $thin-component-border;
  }
}

.folder-nav__folder-input--error {
  border-color: $main-red !important;
}

.accountNav__account-name-text {
  max-width: 100%;
  width: 24.5rem;
  display: inline-block;
  @include m.truncate-ellipsis;
}

.account-nav__folder-name-text {
  max-width: 100%;
  width: 22.5rem;
  display: inline-block;
  @include m.truncate-ellipsis;
}

.site-nav__element {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $spacer-xxl;
  margin: 0 $spacer-s 0 $spacer-xs;
  padding: $spacer-s;
  color: $grey-500;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--panelTextHover);
    border-radius: $border-radius-m;
    color: $white-60;
  }
}

.site-nav__inFolder {
  margin-left: 5.5rem;
}

.site-nav__inFolder--draggable {
  margin-left: $spacer-xxl;

  &:hover {
    .siteNav__drag-icon {
      visibility: visible;
    }
  }
}

.site-nav__draggable {
  margin-left: $spacer-xs;
  cursor: grab;

  &:hover {
    .siteNav__drag-icon {
      visibility: visible;
    }
  }
}

.siteNav__drag-icon {
  visibility: hidden;
  display: inline-block;
  width: $spacer-s;
  height: $spacer-m;
  opacity: 0.6;
  margin-right: $spacer-l;
  cursor: grab;
}

.siteNav--active {
  background-color: var(--panelFontHighlight) !important;
  border-radius: $border-radius-m;
}

.siteNav--highlight {
  background-color: $main-blue-opacity-20;
  border-radius: $border-radius-m;
}

.filterPanel {
  overflow: hidden;
  z-index: $z-index-side-panel;
}

.filterPanel--hidden {
  width: 0;
}

.filterPanel--down {
  top: $action-bar;
}

.searchIconWrapper {
  display: block;
  margin: auto;

  svg {
    display: block;
    margin: auto;
  }
}

.sidePanel__search {
  padding: $spacer-s $spacer-xs $spacer-s $spacer-l;
  border-radius: $border-radius-full;
  display: flex;
  margin: $spacer-l $spacer-s $spacer-xl $spacer-s;
  border: 0.1rem solid $tertiary-black;

  &:hover {
    border: $thin-white-border;
  }

  input::placeholder {
    opacity: 1;
  }
}

.sidePanel__search--focused {
  border: $thin-white-border;

  input::placeholder {
    color: $grey-600 !important;
  }
}

.sidePanel__search-input {
  @extend .copyReg;

  background-color: var(--panelAccent);
  width: 25rem;
  border: none;
  margin-left: $spacer-s;

  &:focus {
    border-color: inherit;
    box-shadow: none;
  }

  @include mobile-and-down {
    width: 15rem;
  }
}

.sidePanel__search-close {
  cursor: pointer;
  width: $spacer-l;
  display: flex;
  align-items: center;
}

.folderInput__close {
  cursor: pointer;
  width: $spacer-l;
  margin-left: -$spacer-xl;
  z-index: 1;
  margin-top: $spacer-xxs;

  &:hover {
    .closeIcon {
      filter: $main-blue-filter;
    }
  }
}

.site--active {
  font-weight: $font-semibold;
}

.folderNav__element--disabled,
.accountNav__element--disabled,
.site-nav__element--disabled {
  opacity: 0.3;
  cursor: default;

  pointer-events: none;
}

.siteNav__site-name {
  display: inline-flex;
  align-self: center;
  max-width: calc(100% - 2.8rem);
}

.createName__body {
  width: 100%;
}

.folder__empty--opacity {
  opacity: 0.6;
}

.emptyFolder-body {
  pointer-events: none;
}

.siteNav__site-name-text {
  max-width: 100%;
  cursor: pointer;
  @include m.truncate-ellipsis;

  @include tablet-and-up {
    max-width: 24.5rem;
  }
}

.siteNav__site-name-text--inFolder {
  @include tablet-and-up {
    max-width: 21.5rem;
  }
}

.sidePanel__right {
  position: absolute;
  height: 100%;
  top: 0;
  left: $side-panel;
  background-color: var(--panelAccent);

  @media (min-width: 400px) {
    width: $side-panel-right;
  }

  @media (max-width: 401px) {
    width: calc(100vw - 6rem);
  }

  ::-webkit-scrollbar-thumb {
    background-color: $grey-36;
    border-radius: $border-radius-m;
  }
}

.sidePanel__right__bottomMargin {
  height: $sidePanel__right__bottomMarginHeight;
}

.sidePanel__right--mobileEnabled {
  visibility: visible !important;
}

.sidePanel__right-header {
  color: $white;
  margin-left: $spacer-s;
  margin-top: $spacer-xl;
}

.sidePanel__right__shadow {
  width: 100%;
  height: $spacer-xxxl;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.sidePanel__right__shadow--top {
  background: linear-gradient(180deg, var(--panelAccent) 0%, $transparent 100%);
  visibility: hidden;
}

.sidePanel__right__shadow--bottom {
  bottom: $sidePanel__right__bottomMarginHeight;
  background: linear-gradient(0deg, var(--panelAccent) 0%, $transparent 100%);
  visibility: visible;
}

.siteNav__new-site-text {
  margin-left: $spacer-s;
  line-height: 2.1rem;
  font-size: $font-size-default;
}

.account-nav_element--unsubscribed {
  background-color: $grey-200;
  color: $main-black !important;
  border-radius: $border-radius-m;
}

.sidePanel__dragHighlight__folder::before {
  content: '';
  position: absolute;
  background-color: var(--primary600);
  opacity: 0.2;
  height: $spacer-xxl;
  width: 25.2rem;
  margin-left: -$spacer-m;
  pointer-events: none;
  border-radius: $border-radius-m $border-radius-m 0 0;
}

.sidePanel__dragHighlight__folder--empty::before {
  content: '';
  position: absolute;
  background-color: var(--primary600);
  opacity: 0.2;
  height: $spacer-xxl;
  width: 25.2rem;
  margin-left: -$spacer-xl;
  pointer-events: none;
  border-radius: 0 0 $spacer-xs $spacer-xs;
}

.sidePanel__dragHighlight::before {
  content: '';
  position: absolute;
  background-color: var(--primary600);
  opacity: 0.2;
  height: $spacer-xxl;
  width: 25.2rem;
  margin-left: -$spacer-l;
  pointer-events: none;

  &:last-of-type {
    border-radius: 0 0 $spacer-xs $spacer-xs;
  }
}

.siteNav__element-empty-folder {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $spacer-xxl;
  margin: 0 $spacer-s 0 $spacer-xxxl;
  padding: 0 $spacer-s;
  transition: all 0.2s;
}

.siteNav__element-new-site {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $spacer-xxl;
  margin: 0 $spacer-s 0 $spacer-xxxl;
  padding: 0 $spacer-s;
  color: $white;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.site-nav__new-site-icon {
  @include m.flex-center;
  flex-shrink: 0;
  width: $spacer-xl;
  height: $spacer-xl;

  img {
    width: $spacer-l;
    height: $spacer-l;
  }
}
