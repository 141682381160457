@use '/src/styles/base/typography' as typography;
@use '../../../../../styles/generic/mixins' as m;
@use '../../../../../styles/variables';

$timelineItemTextMaxWidthPx: 400;

.timeline__head {
  display: flow-root;
  overflow: hidden;
  width: calc(100% - $spacer-s);
  height: 7.5rem;
  line-height: $spacer-l;
  background-color: $grey-200;
  border-bottom: $thin-grey-300-border;
}

.timeline_fieldElement {
  height: $spacer-xxxl;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: $spacer-s;
  position: absolute;
  filter: drop-shadow(0 $spacer-xs $spacer-s rgba(0, 0, 0, 0.2));
  background: $grey-200;
  border-radius: $border-radius-m;
  z-index: 10;
  display: flex;
  margin-top: $spacer-s;
}

.timeline_fieldElement--editable {
  cursor: grab;
}

.timeline_fieldElement--long {
  overflow: hidden;
}

.timeline_fieldElement--short {
  background-color: $grey-500;

  .timeline__fieldElement__priority {
    display: none;
  }
}

.timeline__fieldElement__innerText {
  font-size: $font-size-default;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: $spacer-s;
  color: $main-black;
}

.timeline__fieldElement__innerText__title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline__fieldElement__priority {
  width: $spacer-xl;
  height: $spacer-xl;
  border-radius: $border-radius-s;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: $spacer-s;
  display: flex;
  flex-shrink: 0;
}

.timeline__fieldElement__status {
  width: $spacer-s;
  height: $spacer-s;
  margin: auto;
  display: block;
}

.timelinePriority--HIGH {
  background-color: $main-red;
}

.timelinePriority--MEDIUM {
  background-color: $main-yellow;
}

.timelinePriority--LOW {
  background-color: $priority-low;
}

.timeline__groupedTitleCell {
  font-size: $font-size-default;
  display: flex;
  align-items: center;
  gap: $spacer-s;
  font-weight: $font-semibold;
  border-bottom: $thin-grey-300-border;
  border-right: $thin-grey-300-border;
  position: sticky;
  min-width: 24rem;
  left: 0;
  z-index: 20;
  background-color: $white;

  @include mobile-and-down {
    display: none;
  }
}

.timeline__groupedTitleCell--flagged {
  background-color: $red-75;
  border-right: 0.1 rem solid $grey-200;
}

.timeline__emptyHead {
  position: sticky;
  left: 0;
  z-index: 10;
  background-color: $white;

  @include mobile-and-down {
    display: none;
  }
}

.timeline__emptyMessageElement {
  position: sticky;
  left: 0;
  z-index: 10;
  display: flex;
  background-color: $grey-200;
  min-width: 20rem;
}

.timeline__emptyMessageElement--text {
  margin: auto;
  color: $gray-1;
  font-size: $font-size-default;
}

.timeline__fieldElement__innerText--long {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  display: flex;
  gap: $spacer-xs;

  .timeline__fieldElement__priority {
    display: flex;
  }
}

.timeline__fieldElement__innerText--thin {
  position: relative;
}

.timeline__fieldElement__innerTextLimiter {
  max-width: #{$timelineItemTextMaxWidthPx}px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timeline__head__cell--inner {
  @extend .siteTableFont--semibold;

  width: 100%;
  height: $spacer-xl;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $grey-500;

  span {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.timeline__head__topElement {
  font-weight: $font-semibold;
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: $gray-1;
}

.timeline__head__topElement--secondary {
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: $font-reg;
  color: $grey-500;
}

.timeline__head__bottomElement {
  padding-left: $spacer-xs;
  padding-right: $spacer-xs;
  height: $spacer-l;
  border-radius: $border-radius-full;
  margin: auto;
  line-height: $spacer-l;
  display: inline-block;
}

.timeline__head__bottomElement--active {
  background: var(--primary600);
  color: $white;
}

.timeline__head__cell {
  width: 100%;
  height: 100%;
}

.timeline__head__row__bottom {
  margin-top: -$spacer-m;
}

.timeline__head__row {
  display: flex;
  position: relative;
}

.table__head__cell {
  width: 100%;
  height: 100%;
  line-height: $spacer-xxl;
}

.timeline__row {
  display: flex;
  height: 5.2rem;
  background-color: $white;
  cursor: pointer;

  &:hover {
    background-color: var(--primary75);

    .timeline__groupedTitleCell,
    .table__select {
      background-color: var(--primary75);
    }
  }
}

.timeline__row--flagged {
  background-color: $red-75;
}

.timeline__header__row {
  display: flex;
  height: 5.2rem;
  background-color: $white;
}

.timeline__pointCount {
  color: $grey-500;
  position: absolute;
  right: $spacer-xl;
  top: 1.8rem;
  line-height: $spacer-l;
}

.timeline__headerName {
  overflow: hidden;
  text-overflow: ellipsis;
  height: $spacer-xxl;
  border-radius: 5rem;
  background-color: $grey-200;
  width: calc(var(--timelineGroupWidth) - 8.5rem);
  max-width: 30rem;
  display: flex;
  margin-left: $spacer-xl;
}

.timeline__headerName--text {
  max-width: calc(var(--timelineGroupWidth) - 13rem);
  @include m.truncate-ellipsis;
  line-height: 3.4rem;
  margin-right: $spacer-s;
}

.timeline__arrow {
  height: $spacer-xxl;
  padding-left: $spacer-m;
  padding-right: $spacer-m;
  cursor: pointer;
}

._groupTimeline__field__order__desc,
._groupTimeline__field__order__asc {
  width: $spacer-m;
  padding: $spacer-m;
}

._groupTimeline__field__order__asc--vertical {
  height: $spacer-m;
  margin-top: $spacer-m;
}

._groupTimeline__field__order__desc {
  margin-top: 1.4rem;
  width: $spacer-m;
}

.timeline__cell {
  @extend .siteTableFont;

  @include m.flex-center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  border-right: $thin-grey-300-border;
  color: $main-black;
}

.timeline__cell--group {
  border-bottom: $thin-grey-300-border;
}

.timeline__groupedTitleCell--inner {
  display: flex;
  align-items: center;
  gap: $spacer-s;
  max-width: calc(var(--timelineGroupWidth) - 5.5rem);
  @include m.truncate-ellipsis;
}

.timeline__groupedTitleCell--resize {
  width: $spacer-xs;
  cursor: col-resize;
  right: 0;
  position: absolute;
  height: 5.2rem;
  background-color: var(--timelineResizeBgColor);
}

.timeline__cell__head__bottom {
  margin-bottom: $spacer-m;
  height: $spacer-xxl;
  border-right: none !important;
}

.timeline__cell__head__top {
  margin-top: $spacer-m;
  padding-bottom: 2.8rem;
}

.timeline__cell__head__top__week {
  border-bottom: $thin-grey-300-border;
  padding-bottom: $spacer-xs;
  margin-bottom: $spacer-xl;
  padding-top: $spacer-s;
}

.timeline__cell__head__bottom__week {
  height: $spacer-xxxl;
  margin-top: -$spacer-m;
  padding-top: $spacer-s;
}

.timeline__cell__head {
  @extend .siteTableFont;

  @include m.flex-center;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  border-right: $thin-grey-300-border;
  color: $main-black;
}

.table__cell--dim {
  background-color: $grey-200;

  & > .table__cell__inner {
    opacity: 0.4;
  }
}

.timeline__cell__inner__indicator {
  height: 100%;
  margin: auto;
  padding: 0;
  width: $spacer-xxs;

  background-image: linear-gradient(var(--primary600) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: right;
  background-size: $spacer-xxs $spacer-m;
  background-repeat: repeat-y;
}

.timeline__resizeHandle {
  width: $spacer-s;
  position: absolute;
  height: $spacer-xxxl;
}

.timeline__resizeHandle--left {
  left: 0;
}

.timeline__resizeHandle--right {
  right: 0;
}

.timeline__resizeHandle--editable {
  cursor: ew-resize;
}
