@use '../../../../../styles/base/typography' as t;
@use '../../../../../styles/variables' as *;
@use '../../../../../styles/generic/mixins' as m;

@mixin sidePanelLogo__wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;

  &:focus {
    border-color: inherit;
    box-shadow: none;
  }
}

.mainNav__element {
  height: 4.6rem;
  margin-left: $spacer-s;
  margin-right: $spacer-s;
  border-radius: 5rem;
  cursor: pointer;
  @include m.flex-center;
  color: $white;

  &:hover {
    color: $white;
    background: var(--panelFont);
  }
}

.mainNav__element--active {
  color: $white;
  background: var(--panelFontHighlight) !important;
}

.mainNav__element--with-image {
  @include mobile-and-down {
    display: flex;
    align-items: center;
    padding: $spacer-s;
    gap: $spacer-xxl;
  }
}

.mainNav__element--href {
  flex: auto;
  @include m.flex-center;

  @include mobile-and-down {
    flex: unset;
  }
}

.filterPanel__tooltip {
  &:focus {
    border-color: inherit;
    box-shadow: none;
  }
}

.mainNav__element--disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
    background: unset;
  }
}

.mainNav__element--overview-img {
  width: $spacer-xl;
}

.mainNav__element--img {
  width: $spacer-xl;
  height: $spacer-l;
}

.filterPanel__title {
  font-weight: $font-reg;
  position: relative;
  left: $spacer-xl;
  margin-top: $spacer-m;

  @include tablet-and-up {
    visibility: hidden;
  }
}

.filterPanel__title--img {
  left: $spacer-xxxl;
  @include mobile-and-down {
    margin: 0;
    position: unset;
    left: unset;
  }
}
