.os-theme-input {
  box-sizing: border-box;
  --os-size: 0.4rem;
  --os-padding-perpendicular: 0.1rem;
  --os-padding-axis: 0.2rem;
  --os-track-border-radius: 1rem;
  --os-handle-interactive-area-offset: 0.4rem;
  --os-handle-border-radius: 1rem;
  --os-handle-bg: #98a2b3;
  --os-handle-bg-hover: #475467;
  --os-handle-bg-active: #475467;
}

.os-no-css-vars.os-theme-input.os-scrollbar .os-scrollbar-track {
  border-radius: 1rem;
}
.os-no-css-vars.os-theme-input.os-scrollbar .os-scrollbar-handle {
  border-radius: 1rem;
}
.os-no-css-vars.os-theme-input.os-scrollbar-horizontal {
  padding: 0.2rem 0.2rem;
  right: 1rem;
  height: 1rem;
}
.os-no-css-vars.os-theme-input.os-scrollbar-horizontal.os-scrollbar-rtl {
  left: 1rem;
  right: 0;
}
.os-no-css-vars.os-theme-input.os-scrollbar-horizontal .os-scrollbar-handle {
  min-width: 3.3rem;
  max-width: none;
}
.os-no-css-vars.os-theme-input.os-scrollbar-horizontal .os-scrollbar-handle:before {
  top: calc((0.2rem + 0.4rem) * -1);
  bottom: calc(0.2rem * -1);
}
.os-no-css-vars.os-theme-input.os-scrollbar-vertical {
  padding: 0.2rem 0.2rem;
  bottom: 1rem;
  width: 1rem;
}
.os-no-css-vars.os-theme-input.os-scrollbar-vertical .os-scrollbar-handle {
  min-height: 3.3rem;
  max-height: none;
}
.os-no-css-vars.os-theme-input.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: calc((0.2rem + 0.4rem) * -1);
  right: calc(0.2rem * -1);
}
.os-no-css-vars.os-theme-input.os-scrollbar-vertical.os-scrollbar-rtl .os-scrollbar-handle:before {
  right: calc((0.2rem + 0.4rem) * -1);
  left: calc(0.2rem * -1);
}
.os-no-css-vars.os-theme-input .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.44);
}
.os-no-css-vars.os-theme-input:hover .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.55);
}
.os-no-css-vars.os-theme-input:active .os-scrollbar-handle {
  background: rgba(0, 0, 0, 0.66);
}
