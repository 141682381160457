/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Futura PT Book by ParaType
 * URL: https://www.myfonts.com/products/futura-book-futura-book-71737
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

@font-face {
  font-family: 'FuturaPTBook';
  src: url('./futura-PT-book.woff2') format('woff2'), url('./futura-PT-book.woff') format('woff');
}
