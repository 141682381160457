@import '../../variables';

.filterPanel {
  .filter-content {
    padding: $spacer-xs $spacer-l $spacer-s;
    color: $white;
  }
}

.filter-check {
  margin: $spacer-xs 0;
  input {
    display: none;
  }

  label {
    width: 100%;
    font-weight: $font-reg;
    cursor: pointer;

    span {
      vertical-align: middle;
    }
  }

  .sw-icon {
    margin-right: $spacer-xxs;
  }
}
