@import 'overlayscrollbars/overlayscrollbars.css';
@import '../../../../../styles/variables';
@import '../../../../../styles/components/tags';
//
// --- --- --- customTable --- --- ---
//

.siteTable__wrapper {
  position: relative;
  background-color: $grey-200;
  height: 100%;
  // This is to set the table to the same font-size as the root font-size however we dont want the table to be responsive (yet)
  font-size: 10px;

  ::-webkit-scrollbar-track {
    background-color: $grey-200;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-color: $grey-400 $transparent;
  scrollbar-width: none;
}

.overviewPointContainer {
  position: absolute;
  width: 50vw;
  height: calc(100vh - 10.7rem);
  right: 0;
  bottom: 0;
  z-index: $z-index-point-container;

  @include mobile-and-down {
    width: 100vw;
  }
}

.overviewPointContainer--hidden {
  visibility: hidden;
}
