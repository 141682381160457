@import '../variables';

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon/icomoon.eot?enttc5');
  src: url('/assets/fonts/icomoon/icomoon.eot?enttc5#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon/icomoon.ttf?enttc5') format('truetype'),
    url('/assets/fonts/icomoon/icomoon.woff?enttc5') format('woff'),
    url('/assets/fonts/icomoon/icomoon.svg?enttc5#icomoon') format('svg');
  font-weight: $font-reg;
  font-style: normal;
}

[class^='sw-icon-'],
[class*=' sw-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', serif !important;
  speak: none;
  font-style: normal;
  font-weight: $font-reg;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sw-icon,
.sw-icon-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  color: inherit;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
}

.sw-icon {
  // delete after the icon to font migration
  box-sizing: content-box;
  background-repeat: no-repeat;
}

button.sw-icon {
  padding: 0;
  border: 0;
  background: $transparent;
}

.sw-icon-text {
  font-family: inherit !important;
}

.sw-icon-btn {
  cursor: pointer;
}

.sw-icon-new-point {
  color: $extra-icon !important; // override hover change
}

.sw-icon-new-point::before {
  content: '\e90d';
}

.sw-icon-invite::before {
  content: '\e90b';
}

.sw-icon-plan::before {
  content: '\e912';
}

.sw-icon-activity::before {
  content: '\e901';
}

.sw-icon-spanner::before {
  content: '\e900';
}

.sw-icon-settings::before {
  content: '\e916';
}

.sw-icon-edit-blue::before {
  content: '\e908';
}

.sw-icon-check-blue::before {
  content: '\e905';
}

.sw-icon-cancel::before {
  content: '\e904';
}

.sw-icon-add-blue::before {
  content: '\e902';
}

.sw-icon-search::before {
  content: '\e915';
}

.sw-icon-close::before {
  content: '\e904';
}

.sw-icon-back::before {
  content: '\e903';
}

.sw-icon-edit::before {
  content: '\e908';
}

.sw-icon-pdf::before {
  content: '\e910';
}

.sw-icon-delete::before {
  content: '\e91b';
}

.sw-icon-name::before {
  content: '\e918';
}

.sw-icon-email::before {
  content: '\e909';
}

.sw-icon-password::before {
  content: '\e90c';
}

.sw-icon-photo::before {
  content: '\e911';
}

.sw-icon-videos::before {
  content: '\e919';
}

.sw-icon-documents::before {
  content: '\e906';
}

.sw-icon-check-green::before {
  content: '\e905';
}

.sw-icon-warning::before {
  content: '\e91a';
}

//
// Status Icons
//

.statusIcon {
  display: flex;
  // stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.statusIcon--white {
  fill: white;
}

.statusIcon--gray {
  fill: $grey-500;
}

.statusIcon--sm {
  width: $spacer-s;
  height: $spacer-s;
}

.statusIcon--md {
  width: $spacer-xl;
  height: $spacer-xl;
}
