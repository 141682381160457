$font-x-small: 1.1rem;
$font-small: 1.2rem;
$font-table: 1.3rem;
$font-size-default: 1.4rem;
$font-medium: 1.6rem;
$font-medium-large: 1.8rem;
$font-header: 2.1rem;
$font-header-medium: 2.4rem;
$font-header-large: 2.6rem;
$font-size-large: 2.4rem;
$font-display: 3.2rem;

$font-thinnest: 100;
$font-thin: 300;
$font-reg: 400;
$font-semibold: 600;
$font-weight-display: 700;
