@use '../../styles/base/typography';
@import '../../styles/variables.scss';

.ppTag,
.sw-tag-sm,
.point-view-tags__tag {
  @extend .copySmall;
  display: inline-block;
  position: relative;
  margin: $spacer-xxs 0 $spacer-xxs $spacer-xs;
  padding: $spacer-xxs $spacer-s;
  border-radius: $border-radius-s;
  color: $white;
  background: $grey-500;
  text-overflow: ellipsis;
  max-width: 30vw;
  user-select: none;

  @include mobile-and-down {
    max-width: 10rem;
  }

  &.sw-tag-sm-removable {
    padding: $spacer-xxs 2.8rem $spacer-xxs $spacer-s;
  }

  span {
    line-height: $spacer-l;
  }
}

.site-table__tag {
  font-size: 1.2em;
  line-height: 1.6em;
  font-family: inherit;
  font-weight: $font-semibold;
  display: inline-block;
  position: relative;
  margin: 0.2em 0 0.2em 0.4em;
  padding: 0.2em 0.8em;
  border-radius: 0.3em;
  color: $white;
  background: $grey-500;
  text-overflow: ellipsis;
  max-width: 30vw;
  user-select: none;
  position: inherit !important;

  @include mobile-and-down {
    max-width: 10em;
  }

  &.sw-tag-sm-removable {
    padding: 0.2em 2.8em 0.2em 0.8em;
  }

  span {
    line-height: 1.5em;
  }
}

.site-table__tag--selected {
  background: var(--primary600);
}

.ppTag__remove,
.sw-tag-remove {
  position: absolute;
  top: 0;
  right: 0;
  left: auto;
  height: 100%;
  padding: 0 0.7em;
  border-left: 0.1rem solid $white;
  background: none;
  font-weight: $font-reg;
  font-size: $font-small;

  .sw-icon-close {
    position: absolute;
    top: 50%;
    color: $white;
    transform: translate(-50%, -50%);
  }

  &:hover {
    cursor: pointer;
  }
}

.ppTag__remove {
  padding: 0 $spacer-xxs;
  align-items: center;
  display: flex;
}
